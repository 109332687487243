<template>
  <div class="preferences-frame">
    <div class="frame-2357">
      <div class="preferences mb-5">News Feed Preferences</div>
      <div class="frame-2356">
        <div class="frame-2346">
          <div class="group-2346">

            <div class="section-heading inter-normal-black-20px">Information</div>
            <div class="opportunity-list">
              <div v-for="option in informationOptions" :key="option.id">
                <Checkbox v-model="selectedInformationOptions[option.id]" class="text-[15px]">{{ option.label }}</Checkbox>
              </div>
            </div>
          </div>
          <div class="group-2345">
            <div class="section-heading inter-normal-black-20px mt-5">Opportunities</div>
            <div class="opportunity-list">
              <div v-for="option in opportunityOptions" :key="option.id">
                <Checkbox v-model="selectedOpportunityOptions[option.id]" class="text-[15px]">{{ option.label }}</Checkbox>
              </div>
            </div>
            <div class="h-1">
              <a href="#" @click.prevent="savePreferences" class="green-text link-save">Save</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from "./Atoms/CheckBox.vue";
  import { getAssociatedData, saveAssociatedData, updateAssociatedData } from "../AssociatedData";


  export default {
    name: "StudentPreferences",
    components: {
      Checkbox,
    },
    data() {
      return {
        isInformationInput: false,
        isOpportunitiesInput: false,
        informationOptions: [
          { id: "inStateColleges", label: "In-State Colleges" },
          { id: "communityColleges", label: "Community Colleges" },
          { id: "armedServices", label: "Armed Services" },
          { id: "localBusinesses", label: "Local Businesses" },
        ],
        selectedInformationOptions: {
          inStateColleges: false,
          communityColleges: false,
          armedServices: false,
          localBusinesses: false,
        },
        opportunityOptions: [
          { id: "scholarships", label: "Scholarships" },
          { id: "partTimeJobs", label: "Part-time Jobs" },
          { id: "fullTimeJobs", label: "Full-time Jobs" },
          { id: "apprenticeships", label: "Apprenticeships" },
          { id: "communityService", label: "Community Service" },
          { id: "internships", label: "Internships" },
        ],
        selectedOpportunityOptions: {
          scholarships: false,
          partTimeJobs: false,
          fullTimeJobs: false,
          apprenticeships: false,
          communityService: false,
          internships: false,
        },
      };
    },
    watch: {
      selectedInformationOptions: {
        handler: function (newVal, oldVal) {
        },
        deep: true,
      },
      selectedOpportunityOptions: {
        handler: function (newVal, oldVal) {
        },
        deep: true,
      },
    },
    mounted() {
      this.getPreferences();

    },
    methods: {
      setInformationInput() {
        this.isInformationInput = !this.isInformationInput;
      },
      setOpportunityInput() {
        this.isOpportunitiesInput = !this.isOpportunitiesInput;
      },

      async getPreferences() {
        console.log("get preferences");
        let ad = await getAssociatedData();

        ad.broadcastsInformation.forEach(label => {
          const option = this.informationOptions.find(opt => opt.label === label);
          if (option) {
            console.log("set label true", option.label);
            this.selectedInformationOptions[option.id] = true;
          }
        });


        ad.broadcastsOpportunities.forEach(label => {
          const option = this.opportunityOptions.find(opt => opt.label === label);
          if (option) {
            console.log("set label true", option.label);
            this.selectedOpportunityOptions[option.id] = true;
          }
        });

      },

      async savePreferences() {
        // Get selected Information Options as an array of labels
        let ad = await getAssociatedData();
        console.log("ad", ad);


        // save selected information preferences
        const selectedInformation = Object.keys(this.selectedInformationOptions)
          .filter(key => this.selectedInformationOptions[key])
          .map(key => this.informationOptions.find(option => option.id === key).label);

        console.log("selectedInformation", selectedInformation);

        // save selected opportunities preferences
        const selectedOpportunities = Object.keys(this.selectedOpportunityOptions)
          .filter(key => this.selectedOpportunityOptions[key])
          .map(key => this.opportunityOptions.find(option => option.id === key).label);

        console.log("selectedOpportunities", selectedOpportunities);

        // // Prepare payload for API
        ad.broadcastsInformation =  selectedInformation;
        ad.broadcastsOpportunities = selectedOpportunities;





        // last step save values to api
        await updateAssociatedData(ad);

      }
    },
  };
</script>

<style scoped>
  .preferences-frame {
    align-items: flex-start;
    display: flex;
    min-width: 291px;
    padding: 0 1px;
  }

  @media all and (max-width: 1400px) {
    .preferences-frame {
      display: none;
    }
  }

  .frame-2357 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0px 0px 15px #0000000d;
    display: flex;
    flex-direction: column;
    gap: 13px;
    padding: 30px 14.7px;
    width: 289px;
    max-height: 680px;
    overflow-y: auto;
    scrollbar-width: none;
  }

  .frame-2357::-webkit-scrollbar {
    display: none;
  }
  .green-text {
    color: #259B35;
  }

  .preferences {
    color: #259B35;
    font-family: var(--font-family-inter);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24.2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .frame-2356 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 46px;
    min-height: 680px;
    width: 259px;
  }

  .frame-2346 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: 3px;
    min-height: 367px;
    width: 253px;
  }

  .group-2346 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 139px;
    width: 255px;
  }

  .flex-row-10 {
    align-items: center;
    display: flex;
    gap: 36px;
    height: 33px;
    margin-left: 2px;
    min-width: 251px;
    position: relative;
  }

  .section-heading {
    letter-spacing: 0;
    line-height: normal;
    font-size: 20px;
    font-weight: 400;
    margin-top: 1px;
    min-height: 24px;
    min-width: 182px;
    white-space: nowrap;
  }

  .opportunity-list {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 253px;
  }

  .group-2345 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    min-height: 198px;
    width: 255px;
  }
</style>
