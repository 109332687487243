<template>
  <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
    <p class="text-black">Scholarships for State : <span style="color: #28a745">{{ collegeData.STATE_CODE }}</span></p>
    <hr class="border-b border-gray-200"> 
    <div class="p-4 h-full overflow-y-scroll max-h-[500px] relative">
      <div class="">
        <ul class="">
        <!-- <th
              class="w-96 2xl:w-[400px] text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pt-7 pb-4 sticky top-0 bg-white z-1">
              College Name
            </th> -->
          <li class="text-brand-gray-100 p-3 grid grid-cols-4 gap-4  sticky -top-4 bg-white">
            <p>Name</p>
            <p>City</p>
            <p>State</p>
            <p>Amount</p>
          </li>
          <li v-for="item in curr_scholarships" :key="item" class="font-normal text-[15px] p-3 grid grid-cols-4 gap-4">
            <p>{{ item.SFUND }}</p>
            <p>{{ item.CITY }}</p>
            <p>{{ item.ST }}</p>
            <p>{{item.HIAMNT}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import scholarships from '../../assets/js/scholarship/scholarships'
  export default {
    props:["collegeData"],
    data() {
      return {
        listItems: [
          { name: "Stamps Scholars Award", price: '23,456'}, 
          { name: "George C Awards", price: '23,456'}, 
          { name: "Diversity Award", price: '23,456'}, 
          { name: "Swim", price: '23,456'}, 
          { name: "Polo", price: '23,456'},
        ],
        title: "Scholarship"
      };
    },
    computed:{
      curr_scholarships(){
        return scholarships.find(el => this.collegeData.STATE_CODE.toLowerCase() === el.state_code.toLowerCase())?.json
      }
    }
  };
</script>