<template>
  <div class="">
    <div class="">
      <!-- <pre>{{ recentSurvey }}</pre> -->
      <div
        v-if="recentSurvey"
      >
      <!-- <pre>{{ recentSurvey }}</pre> -->
        <!-- <div class="w-full flex items-center justify-between h-auto">
          <div>
            <h1 class="text-[20px]" style="font-family: Inter">Joe Johnson</h1>
          </div>
          <div class="btns">
            <a href="#" class="single_btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g clip-path="url(#clip0_189_1097)">
                  <path
                    d="M17.34 7.59003L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.75753H9.75C8.5125 6.75753 7.5 7.77003 7.5 9.00753V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.00753C6 6.93753 7.68 5.25753 9.75 5.25753H16.065L12.21 1.27503C11.925 0.975026 11.925 0.502526 12.225 0.217526C12.525 -0.0674737 12.9975 -0.0674737 13.2825 0.232526L17.34 4.42503C18.21 5.29503 18.21 6.72003 17.3325 7.59753L17.34 7.59003ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9.00003C4.5 7.53003 5.1075 6.20253 6.0825 5.25003H3.75C1.68 5.25003 0 6.93003 0 9.00003V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                    fill="#475F4A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_189_1097">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Share
            </a>
            <a href="#" class="single_btn">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                <g clip-path="url(#clip0_189_1101)">
                  <path
                    d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                    fill="#475F4A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_189_1101">
                    <rect width="16" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Export
            </a>
          </div>
        </div> -->
        <div class="">
          <!-- <h1 class="text-[20px] font-semibold" style="font-family: Inter">
            Armed Service Profile
            <span class="text-[12px] inter text-[#A5AC9D] font-[400]">Completed 3 months ago</span>
          </h1> -->
          <p style="font-family: Inter" class="mt-4 font-[400] text-[#A5AC9D]">
            The information below has been collected from your Armed Service Survey.
          </p>
        </div>
        <div class="w-full border border-gray-400 rounded-xl overflow-hidden">
          <div class="w-full grid grid-cols-2 border-b bg-gray-100">
            <div class="w-full p-3 border-r border-gray-400">Questions</div>
            <div class="w-full p-3 ">Answers</div>
          </div>
          <div class="w-full grid grid-cols-2 border-b" v-for="el in recentSurvey" :key="el">
            <div class="w-full p-3 border-r border-gray-400">{{ el.question }}</div>
            <div class="w-full p-3 font-bold">{{ el.answers[0] }}</div>
          </div>
        </div>
        <!-- <div class="px-16 py-10 px-16 w-full">
          <table class="w-full mt-8">
            <thead>
              <tr>
                <th style="font-family: Inter" class="text-gray-400 text-sm w-[25%]">Branches of Interest</th>
                <th style="font-family: Inter" class="text-gray-400 text-sm">Motivations to Join</th>
                <th style="font-family: Inter" class="text-gray-400 text-sm">Post Grad Goals</th>
                <th style="font-family: Inter" class="text-gray-400 text-sm w-[25%]">Post Grad Goals</th>
              </tr>
            </thead>
            <tbody class="mt-4">
              <tr class="spaceUnder">
                <td class="text-center flex gap-4 items-center justify-start" style="font-family: Inter">
                  <img :src="armed_1" />
                  <span class="text-[#475F4A] text-[20px]">Marines</span>
                </td>
                <td class="text-center text-[#475F4A] text-[20px]" style="font-family: Inter">Family Ties</td>
                <td class="text-center text-[#475F4A] text-[20px]" style="font-family: Inter">2 Year College</td>
                <td
                  class="text-center gap-4 text-[#475F4A] text-[20px] flex items-center justify-start"
                  style="font-family: Inter"
                >
                  <img :src="armed_0" />
                  <span>United States Army</span>
                </td>
              </tr>
              <tr class="spaceUnder">
                <td class="text-center flex gap-4 items-center justify-start" style="font-family: Inter">
                  <img :src="armed_1" />
                  <span class="text-[#475F4A] text-[20px]">Navy</span>
                </td>
                <td class="text-center text-[#475F4A] text-[20px]" style="font-family: Inter">Family Ties</td>
                <td class="text-center text-[#475F4A] text-[20px]" style="font-family: Inter">4 Year College</td>
                <td
                  class="text-center gap-4 text-[#475F4A] text-[20px] flex items-center justify-start"
                  style="font-family: Inter"
                >
                  <img :src="armed_0" />
                  <span>Navy Siels</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
      <div v-else class="frame-2342">
        <div class="flex items-center justify-center h-full flex-col w-full">
          <div class="max-w-4xl flex items-center justify-center flex-col gap-4">
            <h1 class="inter text-[#259B35] text-[20px]">Wait! Take Your Armed Services Survey</h1>
            <p class="inter text-center text-[#000] font-[400px]">
              We put the results from your Armed Services Survey here once it is complete. This will help you stay
              organized around which service fits you. Take the survey to get started!
            </p>
            <button
              class="inter px-4 font-[400px] text-[15px] py-2 bg-[#259B35] rounded-full text-white"
              @click="setScreen"
            >
              Take Armed Services Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import armed_0 from "../../../../img/armed_0.png";
  import armed_1 from "../../../../img/armed_1.png";
  import { getAssociatedData } from "../../../AssociatedData.js";

  export default {
    name: "HomeFeedStudentDefault",
    components: {},
    props: ["jj", "leftNavigationBarProps", "topNavigationBarProps", "broadcastFeedProps", "careerProfileFrameProps"],

    data() {
      return {
        armed_0,
        armed_1,
        surveys: [],
        ArmedServices:'',
        recentSurvey:'',
        tabs: [
          { name: "All Forms", active: true },
          { name: "Skill Forms", active: false },
          { name: "Personality Forms", active: false },
        ],
        screenZero: true,
        ArmedForcesLink: "",
      };
    },

  async mounted() {
    await this.fetchSurveys();
    const assocData = await getAssociatedData();
    // console.log('formID Armed: r8oo3J8A');
    // console.log('formID College: nCOP3yFd');
    // console.log('formID Career: IBTtK8kC');
    this.recentSurvey = assocData.typeFormResultMap["r8oo3J8A"]
    },
    methods: {
      setScreen(event) {
        event.preventDefault();
        // this.screenZero = false;
        window.location.assign(this.ArmedForcesLink);
      },
      activateTab(index) {
        this.tabs.forEach((tab, tabIndex) => {
          tab.active = index === tabIndex;
        });
      },
      async fetchSurveys() {
        const response = await this.$request("/v1/surveys", "GET");
        
        this.surveys = await response.data.data.items;
        this.ArmedServices = this.surveys.find((survey) => survey.title.toLowerCase().includes("Armed Services Interest".toLowerCase()));
        if(this.ArmedServices){
          this.ArmedForcesLink = this.ArmedServices?._links?.display;
        }
      },
    },
  };
</script>

<style scoped>
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    min-height: 100vh;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 1512px;
    width: 100%;
  }

  @media all and (max-width: 900px) {
    .home-feed-student-default {
      align-items: flex-start;
      background-color: #f3f7f8;
      border: 1px none;
      display: flex;
      gap: 25px;
      height: 982px;
      overflow: hidden;
      padding: 0 18px;
      position: relative;
      max-width: 100vw !important;
    }
  }

  .flex-col-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    min-height: 1579px;
    position: relative;
    width: 100%;
  }

  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: relative;
    right: 0%;
    max-width: 291px;
  }

  @media all and (max-width: 1400px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  @media all and (max-width: 600px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 120px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }
  .frame-2354 {
    align-items: center;
    display: flex;
    margin-right: 12px;
    min-width: 109px;
  }

  .bell-1 {
    height: 20px;
    width: 20px;
  }

  .cog-1 {
    height: 20px;
    margin-left: 10px;
    width: 20px;
  }

  .overlap-group {
    align-items: flex-start;

    border: 1px solid;
    border-color: var(--celeste);
    border-radius: 23px;
    display: flex;
    height: 46px;
    margin-left: 13px;
    min-width: 46px;
    padding: 10px 11px;
  }

  .jj {
    color: var(--x475f4a);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    min-height: 24px;
    white-space: nowrap;
  }

  .broadcast-feed {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    min-height: 900px;
    width: 100%;
  }

  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    /* min-height: 951px; */
    width: 100%;
  }

  @media all and (max-width: 600px) {
    .overlap-HomeFeedStudentDefaultgroup1 {
      border-radius: 30px;
      /* min-height: 951px; */
      width: 865px;
      margin-top: 80px;
    }
  }

  .frame-2342 {
    align-items: flex-start;
    background-color: transparent;
    border-radius: 30px;
    display: inline-flex;
    gap: 10px;
    width: 100%;
    background-color: #fff;
    /* left: 0; */
    overflow: hidden;
    height: 100%;
    overflow-y: scroll;
    padding: 40px 28px;
    /* position: absolute;s */
    /* top: 0; */
    z-index: -99;
  }

  .posts-feeed::-webkit-scrollbar {
    display: none;
  }

  .grid_div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    width: 100%;
  }

  .first_div div h1 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .personal_info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .personal_info div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .personal_info div p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .personal_info .title_t {
    color: #a5ac9d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;

    display: inline-flex;
    font-weight: 500;
    line-height: normal;
  }
  .personal_info_ .title_t {
    color: #a5ac9d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;

    font-weight: 500;
    line-height: normal;
  }

  .flex_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .second p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .academics {
    margin-top: 30px;
  }
  .academics h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exams {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
  }
  .exams_one {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }

  .exams_one h1 {
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exams_one p {
    color: #636060;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcd {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .abcd p {
    color: #636060;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcd_para {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcde {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    flex-direction: column;
    gap: 10px;
  }

  .second {
    margin-top: 30px;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 14px;
  }
  .single_btn {
    color: #475f4a;
    font-family: Inter;
    font-size: 15px;

    border-radius: 100px;
    display: inline-flex;
    padding: 5px 20px;
    align-items: flex-start;
    gap: 10px;
    background: #f0f4f0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media all and (max-width: 1000px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  @media all and (max-width: 600px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
  tr.spaceUnder > td {
    padding-bottom: 15px;
  }

  .inter {
    font-family: Inter;
  }
</style>
