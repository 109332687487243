<template>
    <div class="navWrapper">
        <div v-if="isManageProviderModal" class="fixed inset-0 z-10 bg-black bg-opacity-20"
            @click="toggleManageProviderModal"></div>


        <div v-if="createProviderModal"
            class="fixed inset-0 bg-black bg-opacity-50 z-30 flex items-center justify-center">
            <div class="w-[600px] max-w-[800px] p-6 rounded-xl bg-white shadow-lg z-40">
                <h2 class="text-xl text-[#000]">Create Provider</h2>
                <p class="text-md text-[#A5AC9D] font-normal mb-4">
                    Creating a new Provider will immediately create their profile and attach your account to the
                    Provider
                </p>

                <form @submit.prevent="saveProvider">
                    <div class="mb-4 flex items-center">
                        <label for="company_name" class="w-[30%] text-sm text-[#475F4A]">Provider</label>
                        <input type="text" id="company_name" required placeholder="Enter new provider name"
                            v-model="newprovider.company_name"
                            class="mt-1 w-full px-3 py-2 border border-[#A5AC9D] rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
                    </div>
                    <div class="mb-4 flex items-center">
                        <label for="primary_user" class="w-[30%] text-sm text-[#475F4A]">Primary User</label>
                        <input type="text" id="primary_user" required placeholder="Account User Name"
                            v-model="newprovider.primary_user"
                            class="mt-1 w-full px-3 py-2 border border-[#A5AC9D] rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
                    </div>
                    <div class="mb-4 flex items-center">
                        <label for="email" class="w-[30%] text-sm text-[#475F4A]">Email</label>
                        <input type="email" id="email" required v-model="newprovider.email" placeholder="Email"
                            class="mt-1 w-full px-3 py-2 border border-[#A5AC9D] rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
                    </div>

                    <div class="mb-4 flex items-center">
                        <label for="password" class="w-[30%] text-sm text-[#475F4A]">Password</label>
                        <input type="text" id="password" required v-model="newprovider.password" placeholder="Password"
                            class="mt-1 w-full px-3 py-2 border border-[#A5AC9D] rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
                    </div>
                    <div class="mb-4 flex items-center">
                        <label for="confirm_password" class="w-[30%] text-sm text-[#475F4A]">Confirm Password</label>
                        <input type="text" id="confirm_password" required v-model="newprovider.confirm_password"
                            placeholder="Confirm Password"
                            class="mt-1 w-full px-3 py-2 border border-[#A5AC9D] rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm" />
                    </div>

                    <div class="mb-4 flex items-center">
                        <label for="type" class="w-[23%] text-sm text-[#475F4A]">Provider Type</label>
                        <CustomSelect :options="['College', 'Armed service', 'Business', 'Non-profit']"
                        v-model="newprovider.provider_type"
                            placeholder="Provider Type" />
                    </div>
                    <div class="mb-4 flex items-center">
                        <label for="state" class="w-[23%] text-sm text-[#475F4A]">State</label>
                        <CustomSelect :options=states v-model="newprovider.state" placeholder="State" />
                    </div>

                    <div class="flex justify-end space-x-4">
                        <button type="button" @click="toggleCreateProviderModal"
                            class="border border-[#fff] text-[#A5AC9D] font-small py-2 px-4 rounded-lg">
                            Cancel
                        </button>
                        <button type="submit"
                            class="bg-[#259B35] text-white font-small py-2 px-6 rounded-[100px]">Save</button>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="isManageProviderModal"
            class="absolute top-20 left-0 right-0 rounded-2xl bg-white shadow-lg mt-2 z-20 modal">
            <div class="flex items-center justify-between">
                <div class="text-[20px] text-[#259B35] font-medium">Providers List</div>

                <button @click="toggleManageProviderModal" class="text-[#475F4A] text-[14px] mt-0 cursor-pointer">
                    <img src="/src/assets/images/close.svg" class="h-[18px]" />
                </button>
            </div>

            <div class="flex items-center gap-3 w-full mb-2 mt-2">
                <SearchBar width="400px" @update="(el) => (searchKey = el)" />
                <CustomSelect :options=states placeholder="State" />
                <CustomSelect :options="['College', 'Armed service', 'Business', 'Non-profit']"
                    placeholder="Provider Type" />
            </div>

            <div class="py-3 px-8 inline-block w-full border rounded-[30px]">
                <div class="overflow-y-auto table_scroll">
                    <table class="w-full">
                        <thead class="sticky top-0 bg-white">
                            <tr>
                                <th v-for="(col, index) in providerTableColNames" :key="index"
                                    class="text-left whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2">
                                    {{ col }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in filteredProviders" :key="rowIndex">
                                <td class="text-black font-[400] text-[15px] whitespace-nowrap p-3 pl-0">
                                    {{ row?.companyName }}
                                </td>
                                <td class="text-black font-[400] text-[15px] whitespace-nowrap p-3 pl-0">
                                    {{ row?.state }}
                                </td>
                                <td class="text-black font-[400] text-[15px] whitespace-nowrap p-3 pl-0">

                                    {{ row?.firstName }} {{ row?.lastName }}
                                </td>
                                <td class="text-black font-[400] text-[15px] whitespace-nowrap p-3 pl-0">
                                    {{ row?.email }}
                                </td>
                            </tr>
                            <tr v-if="!filteredProviders.length">
                                <td colspan="3" class="text-center">No Record found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="logo">
            <router-link to="/educator/home">
                <figure>
                    <img src="/src/assets/images/logo.svg" alt="logo.svg" />
                </figure>
            </router-link>
        </div>

        <div class="buttonGroups">
            <div v-for="btn in buttons" :key="btn.title">
                <router-link :to="btn.router" @click.native="studentDemoHandlerFalse">
                    <div class="btn" :class="{ active: isActive(btn.router) }">
                        {{ btn.title }}
                    </div>
                </router-link>
            </div>
        </div>
        <div class="demoAndProfile">
            <!-- <router-link to="/admin/studentDemo/home" @click.native="studentDemoHandler"> -->
            <button>Student Demo</button>
            <!-- </router-link> -->
        </div>
        <div class="flex items-center gap-3">
            <div class="user-wrap cursor-pointer">
                <div class="logout-popup">
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left">Create User</button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left">Manage Users</button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left"
                        @click="toggleCreateProviderModal">
                        Create Provider
                    </button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left"
                        @click="toggleManageProviderModal">
                        Manage Provider
                    </button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left">Manage School
                        Districts</button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left">Manage Schools</button>
                    <button class="dropBtn text-sm font-medium rounded-md w-full text-left"
                        @click="logoutNow">Logout</button>
                </div>
                <img src="/src/assets/images/scholarpath_admin_user_dummy_image.svg" alt="user" class="w-10 h-10" />
                <h3 class="text-sm font-semibold">Diana ScholarAdmin</h3>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import { useStudentDemo } from "../../store/StudentDemoStates";
import SearchBar from "../Atoms/SearchBar.vue";
import CustomSelect from "../Atoms/CustomSelect.vue";
import { $request } from "../../api";
import { toast } from "vue3-toastify";

const states = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
  "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
  "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
  "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
  "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
  "Wisconsin", "Wyoming"
];

const buttons = ref([
    { title: "Home", router: "/scholarpath-admin/home" },
    { title: "My Broadcasts", router: "/scholarpath-admin/broadcasts" },
    { title: "Insights", router: "/scholarpath-admin/insights" },
    { title: "Admin Settings", router: "/scholarpath-admin/settings" },
    { title: "Resources", router: "/scholarpath-admin/resources" },
]);

const filteredProviders = computed(() => {
    if (!searchKey.value) {
        return providers.value;
    }
    return providers.value.filter(
        (provider) =>
            provider?.firstName?.toLowerCase().includes(searchKey.value.toLowerCase()) ||
            provider?.state?.toLowerCase().includes(searchKey.value.toLowerCase()) ||
            provider?.primary_user?.toLowerCase().includes(searchKey.value.toLowerCase()) ||
            provider?.email?.toLowerCase().includes(searchKey.value.toLowerCase())
    );
});
const providers = ref([]);

const newprovider = ref({
    company_name: "",
    primary_user:"",
    email: "",
    password: "",
    confirm_password: "",
    provider_type: "",
    state: "",
});
const providerTableColNames = ref(["Provider Account", "State", "Primary User", "Email"]);
const isManageProviderModal = ref(false);
const createProviderModal = ref(false);

const searchKey = ref("");

const getProviders = async () => {
    try {
        const response = await $request("/v1/providers", "GET");
        providers.value = response?.data?.data || [];
    } catch (error) {
        console.error("Error fetching providers:", error.response ? error.response.data : error.message);
    }
};

const toggleManageProviderModal = () => {
    if (isManageProviderModal.value === false) {
        getProviders();
    }
    isManageProviderModal.value = !isManageProviderModal.value;
};
const toggleCreateProviderModal = () => {
    createProviderModal.value = !createProviderModal.value;
};

const saveProvider = async () => {
    const payload = {
        firstName: newprovider.value.primary_user?.split(" ")[0],
        lastName: newprovider.value.primary_user?.split(" ")[1],
        email: newprovider.value.email,
        password: newprovider.value.password,
        passwordConfirm: newprovider.value.confirm_password,
        companyName: newprovider.value.company_name,
        userType: newprovider.value.provider_type === "" ? "provider" : newprovider.value.provider_type,
        state: newprovider.value.state,
    }

    try {
        const response = await $request("/v1/user", "POST", payload);
        if (response.status === 200) {

            newprovider.value.name = "";
            newprovider.value.password = "";
            newprovider.value.confirm_password = "";
            newprovider.value.email = "";
            toast.success("Provider created successfully!");
            toggleCreateProviderModal();
        }
    } catch (error) {
        toast.error("Error creating provider:", error.response ? error.response.data : error.message);
    }
};

const route = useRoute();
const store = useStudentDemo();

const isActive = (routePath) => {
    return route.path === routePath;
};
const logoutNow = () => {
    localStorage.clear();
    window.location.href = "/";
};
const studentDemoHandler = () => {
    store.setIsStudentDemo(true);
};
const studentDemoHandlerFalse = () => {
    store.setIsStudentDemo(false);
};
</script>

<style scoped>
.navWrapper {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonGroups {
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px #0000000d;
    height: 100%;
    padding: 11px 25px;
    border-radius: 30px;
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
}

.btn {
    height: 44px;
    padding: 10px 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #a5ac9d;
    font-weight: 400;
}

.btn:hover {
    background-color: #cbeccf;
    color: #259b35;
}

.active {
    background-color: #cbeccf;
    color: #259b35;
}

.demoAndProfile {
    display: flex;
    align-items: center;
    gap: 50px;
}

.demoAndProfile button {
    background-color: #475f4a;
    color: #fff;
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-wrap {
    @apply flex items-center gap-5 relative;
}

.logout-popup {
    @apply w-[210px] rounded-sm bg-white absolute right-[1] top-10 shadow p-2 hidden z-[1000];
}

.dropBtn {
    padding: 5px 10px;
    color: #a5ac9d;
    font-weight: 400;
}

.dropBtn:hover {
    background-color: #cbeccf;
    color: #259b35;
}

.user-wrap:hover .logout-popup {
    @apply block;
}

.modal {
    height: calc(100vh - 120px);
    padding: 20px;
    overflow: hidden;
}

.table_scroll {
    height: calc(100vh - 290px);
}
</style>
