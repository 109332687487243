<template>
  <div class="w-full grid gap-5">
    <div class=" h-[210px]">
      <img src="https://media.istockphoto.com/id/1456749194/photo/college-students-arriving-for-night-school.webp?b=1&s=170667a&w=0&k=20&c=n4vQJWqfdG8NgdO2QRu7bfkvislcKTpTzb_vYp1hjqE=" alt=""
         class="object-cover object-center w-full h-full rounded-[40px] mt-5"
      >
    </div>
    <div>
      <div class="flex mb-6 items-center mt-4">
        <span>{{ collegeData.CMPS_METRO_T }}</span>
        <a :href="collegeData.URL_ADDRESS" target="_blank" class="ml-5">
          <Link width="18" height="18" />
        </a>
      </div>
      
      <p class="text-[15px] px-4">
        <strong class="font-semibold mb-1">About.</strong>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis quisquam qui aut id molestias ab quia commodi dolores, voluptas, voluptatum dolor dolorum suscipit ipsam minima non reiciendis minus. Voluptatibus, quaerat quidem est eum temporibus enim, quod at nemo, dolore obcaecati similique labore possimus! Autem sapiente eum iusto perspiciatis ea fugiat ipsa nesciunt cumque assumenda eveniet dolorem ipsam debitis nostrum, accusamus quaerat voluptatem doloremque maiores fugit nihil voluptates? Vitae dicta culpa dolorem, aspernatur deserunt architecto ratione rem ipsum corporis dolorum, nam minima laboriosam optio nostrum cumque blanditiis velit nihil incidunt accusamus? Est, eum quaerat nihil magni molestias dignissimos ratione distinctio explicabo laborum illo dolor non placeat commodi cupiditate temporibus molestiae obcaecati quam nam corrupti quis voluptatem. Animi maiores fugit necessitatibus nihil vel, non facilis neque, qui, quae tenetur architecto eaque consequatur nostrum distinctio earum voluptate sunt odit ipsa enim minima? Sapiente eveniet voluptatum, dolor, magni placeat excepturi blanditiis et necessitatibus veritatis saepe iste quisquam molestias recusandae possimus eos id facilis ad dignissimos reprehenderit ullam unde quas illum quibusdam repellendus! Voluptates eius placeat iusto odit dolor atque obcaecati labore quam mollitia! Magni asperiores molestias, soluta distinctio officia aliquam facere hic facilis incidunt veritatis dolore dolorem quaerat vitae itaque ipsum numquam vero a?
      </p>
    </div>
    <!-- <div class="rounded-[50px] p-8 bg-white shadow-3xl space-y-4 mb-6 mt-5">
      <h3 class="text-xl font-medium text-black">Scholarship Opportunities</h3>
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block w-full sm:px-6 lg:px-8">
          <table class="w-full" border=1 frame=void rules=rows>
            <thead>
            <tr>
              <th
                v-for="(col, index) in colNames"
                :key="index"
                class="text-left pl-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pb-2"
              >
                {{ col }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in scholarships" :key="rowIndex">
              <td class="text-black font-medium max-w-[250px] min-w-[250px] text-[15px] pl-0 text-left py-4">
                <div class="pl-2.5 truncate w-full">{{ row.title }}</div>
              </td>
              <td class="text-black text-[15px] max-w-[850px] min-w-[850px] text-left">
                <div class="pl-2.5 text-wrap" >{{ row.desc }}</div>
              </td>
            </tr>
            <td colspan="3" class="text-center" v-if="!scholarships.length">No Record found</td>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Link from "@/icons/link.vue";
import Cancel from "@/icons/cancel.vue";

export default {
  components: {Cancel, Link},
  props: [
    "collegeData"
  ],
  data() {
    return {
      colNames: ["Title", "Description", ""],
      // scholarships: [
      //   {
      //     editing: false,
      //     title: "Women in Code Scholarship ad asdasd asdas",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ",
      //   },
      //   {
      //     editing: false,
      //     title: "Change Makers",
      //     desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ...",
      //   },
      // ],
    }
  },
}
</script>
