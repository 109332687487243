import {$request} from './api.js';

export const getAssociatedData = async () => {
  const edLinkData = JSON.parse(localStorage.getItem("edlink"));
  const sid = edLinkData?.id || localStorage.getItem("user.id");

  try {
    const response = await $request('/v1/associatedData/' + sid, 'GET')
    return response.data.data;
  } catch (error) {
    return null;
  }
}; 

export const updateAssociatedData = async (payload) => {
  try {
    const edLinkData = JSON.parse(localStorage.getItem("edlink"));
    const sid = edLinkData?.id || localStorage.getItem("user.id");
    const response = await $request('/v1/associatedData/' + sid + '/save', 'PUT', payload);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const saveAssociatedData = async (payload) => {
  try {
    const edLinkData = JSON.parse(localStorage.getItem("edlink"));
    const sid = edLinkData?.id || localStorage.getItem("user.id");
    console.log("sid", sid);
    const response = await $request('/v1/associatedData', 'POST', { StudentID: sid, ...payload });
    return response;
  } catch (error) {
    return error;
  }
};

export const createAssociatedData = async () => {
  const ed = JSON.parse(localStorage.getItem("edlink"));
  const sid = ed.id;
  const payload = {
    StudentID: sid,
  };
  return await $request('/v1/associatedData', 'POST',  payload);
};

export const saveSkillsAndHobbies = async () => {
  const ed = JSON.parse(localStorage.getItem("edlink"));
  const sid = ed.id;
  const assocData = await getAssociatedData();
  const payLoad = {
    studentID: sid,
    skills: skills.value,
    hobbies: hobbies.value,
    freshman: assocData.freshman,
    sophmore: assocData.sophmore,
    junior: assocData.junior,
    senior: assocData.senior,
    groups: assocData.groups,
    cluster: assocData.cluster,
  };

  const response = await $request('/v1/associatedData', 'PUT',  payLoad);
};
