// src/auth.js

import { googleSdkLoaded } from "vue3-google-login";

export async function fetchData(request) {
  try {
    const response = await request("/v1/provider", "GET");
  } catch (err) {
    throw new Error(err.message || "An error occurred");
  }
}

export async function googleSSO(clearError, googleCallback) {
  clearError();
  googleSdkLoaded((google) => {
    google.accounts.oauth2
      .initCodeClient({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        scope: "email profile openid",
        redirect_uri: import.meta.env.VITE_GOOGLE_REDIRECT_URI,
        callback: googleCallback,
      })
      .requestCode();
  });
}

export async function googleCallback(response, sendCodeToBackend, showError, edLinkLogin) {
  if (response.code) {
    const userDetails = await sendCodeToBackend(response.code);

    // Showing error on google failed
    if (!userDetails) {
      showError(
        "new_error",
        `Oops! It looks like the account you are trying to use has not been
registered yet. Please register and try again.`
      );
      setTimeout(() => {
        let errors = document.querySelectorAll(".new_error");
        for (let error of errors) {
          error.classList.remove("display-error");
        }
      }, 7000);
    } else {
      const payLoad = {
        email: userDetails.email,
        password: userDetails.password,
      };
      try {
        await edLinkLogin(payLoad);
      } catch (e) {
        showError(
          "new_error",
          `Oops! It looks like the account you are trying to use has not been
registered yet. Please register and try again.`
        );
      }
    }
  }
}

export async function sendCodeToBackend(code, request) {
  try {
    const headers = {
      Authorization: code,
    };

    const response = await request("/v1/sso", "GET", null, headers);
    const userDetails = response.data.data;
    return userDetails;
  } catch (error) {
    throw error;
  }
}

export async function getToken(email, id, request) {
  const payload = {
    email: email,
    id: id,
  };

  try {
    const tokenRes = await request("/v1/createtoken", "POST", payload);
    const token = tokenRes.data.data.token;
    localStorage.setItem("token", token);
    console.log("token", token);
  } catch (error) {}
}

export async function edLinkLogin(isGoogleSSO, payLoad, request, storeUser, navigateToHomePageByRole) {
  let user = {};
  clearError();

  try {
    const personResp = await request("/v1/personByEmail", "POST", payLoad);
    if (personResp && personResp.data) {
      user = storeUser(personResp);

      if (isGoogleSSO) {
        // If Google SSO, directly navigate to the homepage by role
        await getToken(user.email, user.id, request);
        await handleAssociatedData(user, request, navigateToHomePageByRole);
      } else {
        if (personResp.data.data.is_registered) {
          // Person is registered, proceed to sign in
          const signInResp = await request("/v1/signin", "POST", payLoad);
          if (signInResp.status === 200) {
            await getToken(user.email, user.id, request);
            await handleAssociatedData(user, request, navigateToHomePageByRole);
          } else {
          }
        } else {
          await sendRegistrationEmail(user, request);
        }
      }
    } else {
    }
  } catch (e) {
    showError(
      "new_error",
      "Oops! It looks like the account you are trying to use has not been\n" +
        "registered yet. Please register and try again."
    );
  }

  return user;
}

export async function handleAssociatedData(user, request, navigateToHomePageByRole) {
    const payload = {
        studentID: user.id,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        gradYear: user.graduation_year ?? 0,
        gpa: Number(user.properties?.custom?.gpa) ?? 0,
        PhoneNumber: user.phone ?? "",
        roles: ["student"],
    };

    try {
        const adResp = await request("/v1/associatedData/" + user.id, "GET");
        if (adResp.status === 404) {
            await request("/v1/associatedData", "POST", payload);
            navigateToHomePageByRole("student");
        } else if (adResp.status === 200) {
            const role = getTopRole(adResp.data.data.roles);
            navigateToHomePageByRole(role);
        }
    } catch (e) {
        if (e.request.status == "404") {
            await request("/v1/associatedData", "POST", payload);
            navigateToHomePageByRole("student");
        } else {
            console.log("e", e);
        }
    }
}

async function sendRegistrationEmail(user, request) {
  try {
    const emailResp = await request("/v1/sendRegistrationEmail", "POST", {
      firstName: user.first_name,
      lastname: user.last_name,
      email: user.email,
    });
    if (emailResp.status === 200) {
    } else {
    }
  } catch (e) {}
}

export function storeUser(resp) {
  let user = {};
  user.first_name = resp.data.data.first_name;
  user.last_name = resp.data.data.last_name;
  user.email = resp.data.data.email;
  user.id = resp.data.data.id;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("email", user.email);
  localStorage.setItem("user.id", user.id);
  user.admin = 0;
  user.educator = 0;
  localStorage.setItem("edlink", JSON.stringify(resp.data.data));
  return user;
}

export function storeMongoUser(resp) {
  let user = {};
  user.first_name = resp.data.data.user.firstname;
  user.last_name = resp.data.data.user.lastname;
  user.email = resp.data.data.user.email;
  user.id = resp.data.data.user.id;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("email", user.email);
  localStorage.setItem("user.id", user.id);
  return user;
}

export function storeScholarPathUser(input) {
  let user = {};
  user.first_name = input.first_name;
  user.last_name = input.last_name;
  user.email = input.plain_email;
  user.admin = input.admin;
  user.educator = input.is_educator;
  user.id = input.plain_email;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("email", user.email);
  localStorage.setItem("user.id", user.id);
  return user;
}

export function navigateToHomePageByRole(role, router) {
  switch (role) {
    case "scholarpathadmin":
      router.push("/scholarpath-admin/home");
      break;
    case "admin":
      router.push("/admin/home");
      break;
    case "business":
      router.push("/provider/feed");
      break;
    case "provider":
      router.push("/provider/feed");
      break;
    case "educator":
      router.push("/educator/home");
      break;
    case "parent":
      router.push("/homeFeed");
      break;
    case "student":
      router.push("/homeFeed");
      break;
    default:
      router.push("/homeFeed");
      break;
  }
}

export function showError(element, msg) {
  document.querySelector("." + element).classList.add("display-error");
  document.querySelector("." + element).innerHTML = msg;
}

export function clearError() {
  let errors = document.querySelectorAll(".error");
  for (let error of errors) {
    error.classList.remove("display-error");
  }
}

export function getTopRole(roles) {
  const rolePriority = ["scholarpathadmin", "admin", "provider", "business", "educator", "parent"];
  if (roles) {
    for (let role of rolePriority) {
      if (roles.includes(role)) {
        return role;
      }
    }
  }
  return "student";
}
