<template>
  <div class="wrapper">
    <h3>Parental Controls</h3>
    <div class="content">
      <div class="mainCheckbox">
        <label class="customCheckbox">
          <input type="checkbox" v-model="allowMessaging" />
          <span class="labeltext"></span>
        </label>
        <p class="mainchecboxText">
          Allow my student to use the messaging tool to communicate with opportunity providers.
        </p>
      </div>
      <p class="mainCheckobDescription">
        A yes answer would allow your student to message a provider and receive messages from providers. They could also
        send their course history, resume, or Armed Services Profile as requested. The parent can access all the
        messages the student has sent and received through the parent login and by going to the Messages tab on the top
        navigation.
        <span
          >A student has to initiate the first message. Providers cannot initiate the first message. A NO answer does
          not affect Newsfeed posts, but will disable messaging for your student.</span
        >
      </p>

      <div v-if="allowMessaging" class="extraContent">
        <div class="mainCheckbox">
          <label class="customCheckbox">
            <input type="checkbox" v-model="allowCollegeMessaging" />
            <span class="labeltext"></span>
          </label>
          <p class="mainchecboxText">
            Allow my student to use the messaging tool to communicate with college admissions.
          </p>
        </div>
        <div class="mainCheckbox">
          <label class="customCheckbox">
            <input type="checkbox" v-model="allowRecruiterMessaging" />
            <span class="labeltext"></span>
          </label>
          <p class="mainchecboxText">
            Allow my student to use the messaging tool to communicate with Armed Services recruiters.
          </p>
        </div>
        <div class="mainCheckbox">
          <label class="customCheckbox">
            <input type="checkbox" v-model="allowBusinessMessaging" />
            <span class="labeltext"></span>
          </label>
          <p class="mainchecboxText">
            Allow my student to use the messaging tool to communicate with local businesses.
          </p>
        </div>
        <div class="mainCheckbox">
          <label class="customCheckbox">
            <input type="checkbox" v-model="receiveEmailNotifications" />
            <span class="labeltext"></span>
          </label>
          <p class="mainchecboxText">
            In addition to being able to view messages at any time through the ScholarPath platform, I (the parent)
            would like to receive email notifications for any message sent or received from a provider.
          </p>
        </div>
      </div>
    </div>
    <div class="btn">
      <button @click="saveSettings">Save Settings</button>
    </div>
  </div>
</template>

<script>
  import { getAssociatedData, updateAssociatedData } from "../../AssociatedData";

  export default {
    data() {
      return {
        allowMessaging: false,
        allowCollegeMessaging: false,
        allowRecruiterMessaging: false,
        allowBusinessMessaging: false,
        receiveEmailNotifications: false,
      };
    },
    methods: {
      async saveSettings() {
        const settings = {
          allowMessaging: this.allowMessaging,
          allowCollegeMessaging: this.allowCollegeMessaging,
          allowRecruiterMessaging: this.allowRecruiterMessaging,
          allowBusinessMessaging: this.allowBusinessMessaging,
          receiveEmailNotifications: this.receiveEmailNotifications,
        };

        // get associated data
        const ad = await getAssociatedData();
        // update associated data
        ad.allow_messenger = this.allowMessaging;
        ad.allow_college_admissions = this.allowCollegeMessaging;
        ad.allow_armed_services = this.allowRecruiterMessaging;
        ad.allow_local_businesses = this.allowBusinessMessaging;
        ad.receive_email_notifications = this.receiveEmailNotifications;

        await updateAssociatedData(ad);


        console.log(settings);
        this.$emit("continue", settings);
      },
    },
  };
</script>

<style scoped>
  .wrapper {
    h3 {
      font-size: 18px;
      color: #475f4a;
    }
  }

  .content {
    padding: 20px 30px 0 30px;
    min-height: 300px;
    overflow: auto;
  }

  .mainCheckbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .mainchecboxText {
    margin-left: 60px;
    font-size: 20px;
    color: hsba(128, 25%, 37%, 1);
    padding: 0;
    font-weight: 300;
  }

  .mainCheckobDescription {
    margin-top: 20px;
    font-style: italic;
    font-size: 18px;
    color: hsba(128, 25%, 37%, 1);
    font-weight: 300;
  }

  .mainCheckobDescription span {
    color: rgba(37, 155, 53, 1);
  }
  .extraContent {
    margin-top: 20px;
  }
  .customCheckbox {
    display: block;
    position: relative;
    margin-top: 5px;

    input {
      display: none;

      &:checked ~ .labeltext::before {
        left: 30px;
      }
      &:checked ~ .labeltext::after {
        background-color: #475F4A;
      }
    }

    .labeltext {
      position: relative;
      padding: 0 0 0 50;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 48px;
        height: 20px;
        background-color: rgba(221, 221, 221, 1);
        border-radius: 50px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 2.2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background-color: #fff;
        z-index: 1;
        border-radius: 50px;
        transition: all ease-in-out 0.3s;
      }
    }
  }

  .btn {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  button {
    background-color: rgba(37, 155, 53, 1);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
  }
</style>
