<template>
  <div class="w-full h-min flex flex-col items-center justify-center pt-5">
    <div class="w-full flex items-center justify-between text-xs text-gray-500 py-2 hidden">
      <div>
        Complete Survey:
        <span class="info-values"> {{ Math.round((surveyPercentage + Number.EPSILON) * 100) / 100 }} % </span>
      </div>
      <div>
        GPA:
        <span class="info-values">
          {{ gpa }}
        </span>
      </div>
      <div>
        Attendance:
        <span class="info-values">
          {{ attendance }}
        </span>
      </div>
    </div>
    <div class="w-full flex items-center justify-between">
      <div class="">
        <router-link to="/homeFeed" class="flex items-center gap-3">
          <img class="h-10" src="../assets/images/logo.svg" alt="SP Logo" />
        </router-link>
      </div>
      <div class="top-navigation-bar">
        <router-link to="/homeFeed" class="nav-link" :class="[currentLink === '/homeFeed' ? 'parent_a' : '']">
          Home
        </router-link>
        <div class="relative discover">
          <div class="flex items-center gap-2 nav-link" :class="[currentLink === '/surveyDefault' ? 'parent_a' : '']">
            <router-link to="/surveyDefault" class="link" :class="[currentLink === '/surveyDefault' ? 'parent_a' : '']">Discover</router-link>
          </div>
        </div>
        <router-link
          to="/researchDefault"
          class="nav-link"
          :class="[currentLink === '/researchDefault' ? 'parent_a' : '']"
        >
          Explore
        </router-link>
        <!-- <div class="relative plan" >
          <div class="nav-link">
            Plan & build
            <img src="@/assets/images/arrow-down.svg" alt="">
          </div>
          <ul class="dropdown plan-dropdown">
            <li class="dropdown-item">
              <router-link to="/resumeDefault" class="link" :class="[currentLink === '/resumeDefault' ? 'parent_a' : '']">
                Resume Builder
              </router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="/fouryearsplan" class="link" :class="[currentLink === '/fouryearsplan' ? 'parent_a' : '']">
                Academic Planner
              </router-link>
            </li>
            <li class="dropdown-item">
              <router-link to="" class="link">Post-HS Goals</router-link>
            </li>
          </ul>
        </div>
        <div class="relative portfolio" >
          <div class="nav-link">
            Portfolio
            <img src="@/assets/images/arrow-down.svg" alt="">
          </div>
          <ul class="dropdown portfolio-dropdown">
            <li class="dropdown-item" :class="[currentLink === '/transcript' ? 'parent_a' : '']">
              <router-link to="/transcript" class="link">Course History</router-link>
            </li>
            <li class="dropdown-item" :class="[currentLink === '/armed' ? 'parent_a' : '']">
              <router-link to="/armed" class="link">Armed Service Profile</router-link>
            </li>
          </ul>
        </div> -->
        <router-link to="/portfolio" class="nav-link" :class="[currentLink === '/portfolio' ? 'parent_a' : '']">
          Portfolio
        </router-link>
        <router-link to="/messages" class="nav-link" :class="[currentLink === '/messages' ? 'parent_a' : '']">
          Messages
        </router-link>
        <div class="relative resource">
          <div class="nav-link">
            Resources
            <img src="@/assets/images/arrow-down.svg" alt="" />
          </div>
          <ul class="dropdown resource-dropdown">
            <li class="dropdown-item">
              <router-link to="" class="link" :class="[currentLink === '' ? 'parent_a' : '']"> FAQs </router-link>
            </li>
            <li class="dropdown-item">
              <router-link class="link" to="">Tutorials</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex items-center gap-3">
        <!--
        <div class="h-5 border border-primary/50"></div> -->
        <div class="user-wrap">
          <div class="logout-popup">
            <button
              class="px-2 py-1.5 text-primary text-sm font-medium rounded-md w-full text-center hover:bg-primary/20"
              @click="logoutNow"
            >
              Logout
            </button>
          </div>
          <img src="@/assets/images/user.png" alt="user" class="w-10 h-10 cursor-pointer" />
          <div>
            <p class="text-sm font-semibold">{{ userName }}</p>
            <p class="text-[#A5AC9D] text-xs">
              <span class="text-base text-[#475F4A]"
                >{{ Math.round((surveyPercentage + Number.EPSILON) * 100) / 100 }} %
              </span>
              Complete surveys
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import listCheck from "../icons/listCheck.vue";
  import { getAssociatedData } from "../AssociatedData.js";

  export default {
    name: "TopNavigationBar",
    data() {
      return {
        currentLink: null,
        userName: "Student",
        resume: [],
        daName: "",
        attendance: "",
        group: {
          name: "",
          description: "",
        },
        gpa: "",
        listCheck: listCheck,
        modal: false,
        allGroups: [],
        showGroups: false,
        surveys: [],
        surveyPercentage: "",
      };
    },
    created() {
      this.currentLink = this.$route.path;
    },
    methods: {
      goToSurvey(link) {
        this.$router.push(link);
        this.currentLink = this.$route.path;
      },
      logoutNow() {
        localStorage.clear();
        window.location.href = "/";
      },
      async fetchSurveys() {
        const response = await this.$request("/v1/surveys", "GET");
        this.surveys = await response.data.data.items;
        const ad = await getAssociatedData();
        let complete = 0;
        if (ad) {
          for (let i = 0; i < this.surveys.length; i++){
            if (ad.typeFormResultMap?.[this.surveys?.[i]?.id]) {
              complete++;
            }
          }
        }
        this.surveyPercentage = complete/this.surveys.length * 100;
      },
      async getUserDetails() {
        const user = JSON.parse(localStorage.getItem("user"));
        const resume = JSON.parse(localStorage.getItem("resume"));
        const edlink = JSON.parse(localStorage.getItem("edlink"));
        this.resume = resume;
        this.daName = user?.first_name;
        this.attendance = parseFloat(edlink?.properties?.custom?.attendance)?.toFixed(2);
        this.gpa = parseFloat(edlink?.properties?.custom?.gpa)?.toFixed(2); //user?.gpa;
        this.currentLink = this.$route.path;
        await this.fetchSurveys();
      },
      getUser() {
        const usrData = localStorage.getItem("user");
        if (usrData) {
          const usr = JSON.parse(usrData);
          this.userName = usr.first_name;
        } else {
        }
      },
    },

    mounted() {
      this.getUser();
      this.getUserDetails();
    },
  };
</script>

<style scoped>
  .top-navigation-bar {
    background-color: var(--white);
    box-shadow: 0px 0px 12px #0000000d;
    height: 60px;
    @apply rounded-full px-6 py-3 flex items-center gap-2.5;
  }
  .dropdown {
    @apply absolute left-3 top-7 w-60 rounded-xl border border-gray-200 bg-white z-[99999] shadow-lg hidden overflow-hidden;
  }
  .dropdown-item {
    @apply text-sm  border-b border-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-600 cursor-pointer;
  }
  .dropdown-item a,
  .dropdown-item .link {
    @apply w-full h-full inline-block px-3 py-2;
  }
  .nav-link {
    @apply rounded-full flex items-center justify-center gap-2 px-5 py-2.5 text-sm text-gray-700 hover:text-primary cursor-pointer relative;
  }
  .parent_a {
    @apply bg-primary-light text-primary font-semibold;
  }

  .active {
    color: #259b35 !important;
  }
  .discover:hover .discover-dropdown {
    @apply inline-block;
  }
  .portfolio:hover .portfolio-dropdown {
    @apply inline-block;
  }
  .plan:hover .plan-dropdown {
    @apply inline-block;
  }
  .resource:hover .resource-dropdown {
    @apply inline-block;
  }
  .info-values {
    @apply text-sm font-semibold text-primary;
  }
  .user-wrap {
    @apply flex items-center gap-5 relative;
  }
  .logout-popup {
    @apply w-32 rounded-lg bg-white absolute left-0 top-11 shadow p-2 hidden;
  }
  .user-wrap:hover .logout-popup {
    @apply block;
  }
</style>
