import axios from "axios";

export const $request = async (endpoint, method, data = {}, headers = {}) => {
  const buildToken = import.meta.env.VITE_BUILD_TOKEN || "unknown";
  const serverUrl = import.meta.env.VITE_APP_SERVER_URL || "http://localhost:8080";
  const url = `${serverUrl}/api${endpoint}`;
  const token = localStorage.getItem("token");

  const defaultHeaders = {
    "X-Build-Token": buildToken,
    token: token,
    ...headers,
  };

  try {
    const response = await axios({
      url,
      method,
      data,
      headers: defaultHeaders,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401 && token) {
      const app = document.querySelector("#app");
      if (app) {
        // app.dispatchEvent(new CustomEvent("show-alert", { detail: { message: "Session Expired" } }));
      }
    }
    throw error;
  }
};

export const $requestResume = async (userid) => {
  try {
    const response = await this.$request("/v1/resume?userid=" + userid, "GET");
    const resume = response.data.data;
    localStorage.setItem("resume", JSON.stringify(resume));
  } catch (error) {}
};
