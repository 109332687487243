<!-- <template>
  <div class="w-full flex max-md:flex-wrap gap-8 items-start justify-between">
    <div class="lg:block hidden w-[270px] shrink-0">
      <figure>
        <img src="../../../assets/images/logo.svg" alt="logo.svg" />
      </figure>
    </div>
    <div class="px-10 py-3 shadow-3xl bg-white rounded-full">
      <ul class="flex gap-6 xl:gap-12 items-start">
        <li class="">
          <router-link
            to="/admin/home"
            class="group flex flex-col gap-0.5 items-center"
          >
            <div
              class="w-10 h-10 rounded-full flex justify-center items-center group-[.router-link-active]:bg-primary group-[.router-link-active]:bg-opacity-[13%]"
            >
              <svg
                width="23"
                class="fill-brand-gray-100 group-[.router-link-active]:fill-primary"
                height="20"
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5004 4.49258L2.77437 12.0203C2.70969 12.0773 2.62703 12.1039 2.55595 12.1512V19.3754C2.55595 19.5411 2.62326 19.7001 2.74308 19.8173C2.86289 19.9345 3.0254 20.0004 3.19484 20.0004H8.30595C8.4754 20.0004 8.6379 19.9345 8.75772 19.8173C8.87753 19.7001 8.94484 19.5411 8.94484 19.3754V14.3754C8.94484 14.2096 9.01215 14.0507 9.13197 13.9334C9.25178 13.8162 9.41429 13.7504 9.58373 13.7504H13.4171C13.5865 13.7504 13.749 13.8162 13.8688 13.9334C13.9886 14.0507 14.056 14.2096 14.056 14.3754V19.3754C14.056 19.5411 14.1233 19.7001 14.2431 19.8173C14.3629 19.9345 14.5254 20.0004 14.6948 20.0004H19.806C19.9754 20.0004 20.1379 19.9345 20.2577 19.8173C20.3775 19.7001 20.4448 19.5411 20.4448 19.3754V12.1527C20.377 12.1074 20.2963 12.0816 20.2348 12.0277L11.5004 4.49258ZM22.7884 9.23007L12.5685 0.401951C12.276 0.143241 11.8957 0 11.5014 0C11.1071 0 10.7268 0.143241 10.4343 0.401951L0.212029 9.23007C0.149586 9.28493 0.0988021 9.35128 0.0625783 9.42534C0.0263544 9.49939 0.00540059 9.57971 0.000913761 9.66169C-0.00357307 9.74367 0.00849503 9.82571 0.0364286 9.90313C0.0643623 9.98055 0.107614 10.0518 0.163713 10.1129L1.01863 11.0434C1.0747 11.1044 1.14252 11.1541 1.21823 11.1896C1.29393 11.225 1.37603 11.2455 1.45983 11.2499C1.54364 11.2543 1.6275 11.2425 1.70664 11.2151C1.78578 11.1878 1.85864 11.1455 1.92106 11.0906L11.0779 3.18906C11.1947 3.08833 11.3449 3.03274 11.5006 3.03274C11.6562 3.03274 11.8065 3.08833 11.9233 3.18906L21.0801 11.0902C21.1426 11.1451 21.2154 11.1874 21.2946 11.2147C21.3737 11.2421 21.4576 11.2539 21.5414 11.2495C21.6252 11.2451 21.7073 11.2246 21.783 11.1892C21.8587 11.1537 21.9265 11.1041 21.9826 11.043L22.8371 10.1125C22.9502 9.98915 23.0086 9.82689 22.9995 9.66142C22.9904 9.49594 22.9144 9.34079 22.7884 9.23007Z"
                />
              </svg>
            </div>
          </router-link>
        </li>
        <li class="">
          <router-link
              to="/admin/settings"
              class="group flex flex-col gap-0.5 items-center"
          >
            <div
                class="w-10 h-10 rounded-full flex justify-center items-center group-[.router-link-active]:bg-primary group-[.router-link-active]:bg-opacity-[13%]"
            >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.375 2.8125H11.25V1.875C11.25 1.53125 10.9688 1.25 10.625 1.25H9.375C9.03125 1.25 8.75 1.53125 8.75 1.875V2.8125H0.625C0.28125 2.8125 0 3.09375 0 3.4375V4.0625C0 4.40625 0.28125 4.6875 0.625 4.6875H8.75V5.625C8.75 5.96875 9.03125 6.25 9.375 6.25H10.625C10.9688 6.25 11.25 5.96875 11.25 5.625V4.6875H19.375C19.7188 4.6875 20 4.40625 20 4.0625V3.4375C20 3.09375 19.7188 2.8125 19.375 2.8125ZM19.375 15.3125H6.25V14.375C6.25 14.0312 5.96875 13.75 5.625 13.75H4.375C4.03125 13.75 3.75 14.0312 3.75 14.375V15.3125H0.625C0.28125 15.3125 0 15.5938 0 15.9375V16.5625C0 16.9062 0.28125 17.1875 0.625 17.1875H3.75V18.125C3.75 18.4688 4.03125 18.75 4.375 18.75H5.625C5.96875 18.75 6.25 18.4688 6.25 18.125V17.1875H19.375C19.7188 17.1875 20 16.9062 20 16.5625V15.9375C20 15.5938 19.7188 15.3125 19.375 15.3125ZM19.375 9.0625H16.25V8.125C16.25 7.78125 15.9688 7.5 15.625 7.5H14.375C14.0312 7.5 13.75 7.78125 13.75 8.125V9.0625H0.625C0.28125 9.0625 0 9.34375 0 9.6875V10.3125C0 10.6562 0.28125 10.9375 0.625 10.9375H13.75V11.875C13.75 12.2188 14.0312 12.5 14.375 12.5H15.625C15.9688 12.5 16.25 12.2188 16.25 11.875V10.9375H19.375C19.7188 10.9375 20 10.6562 20 10.3125V9.6875C20 9.34375 19.7188 9.0625 19.375 9.0625Z" fill="#259B35"/>
              </svg>

            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="w-[270px] shrink-0 flex justify-end">
      <ul class="flex gap-5 items-center">
        <li>
          <router-link to="/notification" class="relative inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="20"
              fill="none"
              viewBox="0 0 19 20"
            >
              <path
                fill="#475F4A"
                d="M3.39 15h13.047a2.5 2.5 0 002.35-3.35l-2.376-6.572A6.936 6.936 0 002.96 5.43l-1.958 6.333A2.5 2.5 0 003.39 15zM5.917 16.666a4.167 4.167 0 008.166 0H5.917z"
              ></path>
            </svg>
            <div
              class="absolute -top-2 -left-0.5 w-3 h-3 rounded-full bg-primary"
            ></div>
          </router-link>
        </li>
        <li>
          <router-link to="/settings"
            ><svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.3745 11.3711L19.0392 12.332C19.2293 12.4417 19.3175 12.6687 19.2524 12.8783C18.82 14.2706 18.0817 15.5284 17.1161 16.5731C17.0439 16.6513 16.9472 16.7026 16.8419 16.7186C16.7366 16.7346 16.629 16.7143 16.5368 16.6611L14.8733 15.7004C14.1734 16.3 13.3688 16.7653 12.4999 17.0726V18.9938C12.4999 19.1004 12.4636 19.2037 12.3971 19.2869C12.3305 19.3701 12.2376 19.4282 12.1336 19.4515C10.7682 19.7582 9.30163 19.774 7.86726 19.4518C7.65281 19.4037 7.49995 19.2137 7.49995 18.9939V17.0726C6.63106 16.7653 5.82642 16.3001 5.12656 15.7004L3.46312 16.6611C3.37088 16.7143 3.26329 16.7346 3.158 16.7186C3.05272 16.7026 2.956 16.6513 2.88374 16.5731C1.9182 15.5284 1.17988 14.2706 0.747454 12.8783C0.682337 12.6687 0.770579 12.4418 0.960696 12.332L2.62542 11.3711C2.45822 10.4647 2.45822 9.53537 2.62542 8.62897L0.960735 7.66807C0.770618 7.55835 0.682376 7.33143 0.747493 7.12178C1.17992 5.72952 1.9182 4.4717 2.88378 3.42702C2.95604 3.34879 3.05276 3.29747 3.15804 3.28147C3.26333 3.26547 3.37092 3.28574 3.46316 3.33897L5.1266 4.29971C5.82648 3.70004 6.63111 3.23481 7.49999 2.92745V1.00624C7.49999 0.899683 7.53626 0.796303 7.60284 0.713109C7.66941 0.629914 7.76232 0.571861 7.86628 0.548501C9.23175 0.24186 10.6983 0.226001 12.1327 0.548227C12.3471 0.596391 12.5 0.786352 12.5 1.00616V2.92741C13.3688 3.23476 14.1735 3.69999 14.8734 4.29967L16.5368 3.33893C16.629 3.28571 16.7366 3.26543 16.8419 3.28143C16.9472 3.29743 17.0439 3.34875 17.1162 3.42698C18.0817 4.47167 18.82 5.72948 19.2525 7.12174C19.3176 7.33135 19.2293 7.55827 19.0392 7.66803L17.3745 8.62893C17.5417 9.53535 17.5417 10.4647 17.3745 11.3711ZM13.125 10C13.125 8.2769 11.7231 6.87502 9.99996 6.87502C8.27683 6.87502 6.87495 8.2769 6.87495 10C6.87495 11.7232 8.27683 13.125 9.99996 13.125C11.7231 13.125 13.125 11.7232 13.125 10Z"
                fill="#475F4A"
              />
            </svg>
          </router-link>
        </li>
        <li class="!border border-brand-gray-200 rounded-full">
          <router-link
            to="#"
            class="flex justify-center items-center w-12 h-12"
          >
            <p class="text-center font-semibold text-xl text-secondary">JP</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
</script> -->




<template>
  <div class="navWrapper">
    <div class="logo">
      <router-link to="/educator/home">
        <figure>
          <img src="/src/assets/images/logo.svg" alt="logo.svg" />
        </figure>
      </router-link>
    </div>
    <div class="buttonGroups">
      <div v-for="btn in buttons" :key="btn.title">
        <router-link :to="btn.router" @click.native="studentDemoHandlerFalse">
          <div class="btn" :class="{ active: isActive(btn.router) }">
            {{ btn.title }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="demoAndProfile">
      <!-- <router-link to="/admin/studentDemo/home" @click.native="studentDemoHandler"> -->
      <button>Student Demo</button>
      <!-- </router-link> -->
    </div>
    <div class="flex items-center gap-3">
      <div class="user-wrap cursor-pointer">
        <div class="logout-popup">
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Create User
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Manage Users
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Create Provider
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Manage Provider
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Manage School Districts
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left">
            Manage Schools
          </button>
          <button class="dropBtn text-sm font-medium rounded-md w-full text-left" @click="logoutNow">
            Logout
          </button>

        </div>
        <img src="/src/assets/images/admin_user_dummy_image.svg" alt="user" class="w-10 h-10" />
        <h3 class="text-sm font-semibold">Paul Admin</h3>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { useStudentDemo } from "../../../store/StudentDemoStates";

const buttons = ref([
  { title: "Home", router: "/admin/home" },
  { title: "My Broadcasts", router: "/admin/broadcasts" },
  { title: "Insights", router: "/admin/insights" },
  { title: "Admin Settings", router: "/admin/settings" },
  // { title: "Resources", router: "/admin/resources" },
  { title: "Company Profile", router: "/admin/providers" },
]);

const route = useRoute();
const store = useStudentDemo();

const isActive = (routePath) => {
  return route.path === routePath;
};
const logoutNow = () => {
  localStorage.clear();
  window.location.href = "/";
};
const studentDemoHandler = () => {
  store.setIsStudentDemo(true);
};
const studentDemoHandlerFalse = () => {
  store.setIsStudentDemo(false);
};
</script>

<style scoped>
.navWrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonGroups {
  background-color: #fff;
  box-shadow: 0px 0px 12px 0px #0000000d;
  height: 100%;
  padding: 11px 25px;
  border-radius: 30px;
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}

.btn {
  height: 44px;
  padding: 10px 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #a5ac9d;
  font-weight: 400
}

.btn:hover {
  background-color: #cbeccf;
  color: #259b35;
}

.active {
  background-color: #cbeccf;
  color: #259b35;
}

.demoAndProfile {
  display: flex;
  align-items: center;
  gap: 50px;
}

.demoAndProfile button {
  background-color: #475f4a;
  color: #fff;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-wrap {
  @apply flex items-center gap-5 relative;
}

.logout-popup {
  @apply w-[210px] rounded-sm bg-white absolute right-[-1] top-10 shadow p-2 hidden z-[1000];
}

.dropBtn {
  padding: 5px 10px;
  color: #a5ac9d;
  font-weight: 400
}

.dropBtn:hover {
  background-color: #cbeccf;
  color: #259b35;
}

.user-wrap:hover .logout-popup {
  @apply block;
}
</style>
