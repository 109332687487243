<template>
  <div class="w-full h-[90vh]">
    <JobTablePopup v-if="showJobPopup" :job="selectedJob" @close="showJobPopup = false" @update="showJob" />
    <MainLayout>
      <div class="w-full border-gray-400 bg-white rounded-3xl flex flex-col items-center justify-start box-border p-5">
        <div class="w-full flex justify-between flex-wrap mb-3">
          <ul class="flex items-center gap-4 mb-2 sm:mb-0 flex-wrap">
            <li
              v-for="(filter, index) in filters"
              :key="filter"
              :class="[
                index === currentFilter
                  ? 'text-white bg-[var(--style)] px-4 py-1 text-sm rounded-full inter-medium-white-20px'
                  : 'px-4 py-1 text-sm rounded-full text-gray-500 bg-[var(--style-percent-13)]',
                'cursor-pointer all inter-medium-white-20px',
              ]"
              @click="setFilter(index)"
            >
              {{ filter }}
            </li>
          </ul>
          <div class="w-full sm:w-auto flex items-center justify-center">
            <button v-if="currentFilter !== 0" @click="triggerReset" class="reset_button">Reset Filters</button>
            <SearchBar placeholder="Search" @update="(v) => (searchKey = v)" />
          </div>
        </div>

        <div class="w-full h-[90%] relative flex items-center justify-center">
          <JobTable v-if="currentFilter === 0" @update="showJob" :searchKey="searchKey" ref="jobTable" />
          <OccupationTable v-else-if="currentFilter === 1" :searchKey="searchKey" ref="occupationTable" />
          <NewCollegeTable
            v-else-if="currentFilter === 2"
            :searchKey="searchKey"
            @triggered="onChildTriggered"
            ref="newCollegeTable"
          />
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script>
  import MainLayout from "../layouts/MainLayout.vue";

  import jobData from "../assets/js/jobData";
  import JobTable from "./Tables/JobTable.vue";
  import JobTablePopup from "./Tables/JobTablePopup.vue";
  import NewCollegeTable from "./Tables/NewCollegeTable.vue";
  import OccupationTable from "./Tables/OccupationTable.vue";
  import { useRoute } from "vue-router";
  import SearchBar from "./Atoms/SearchBar.vue";

  export default {
    name: "HomeFeedStudentDefault",
    components: {
      MainLayout,
      SearchBar,
      JobTable,
      OccupationTable,
      JobTablePopup,
      NewCollegeTable,
    },
    props: ["jj", "leftNavigationBarProps", "topNavigationBarProps", "broadcastFeedProps", "careerProfileFrameProps"],

    data() {
      return {
        surveys: [],
        tabs: [
          { name: "All Forms", active: true },
          { name: "Skill Forms", active: false },
          { name: "Personality Forms", active: false },
        ],
        searchKey: "",
        selectedJob: "",
        jobData,
        showJobPopup: false,
        filters: ["Jobs", "Occupations", "Colleges"],
        currentFilter: 0,
      };
    },
    mounted() {
      this.fetchSurveys();
      const route = useRoute();
      const tab = route.query.tab;
      if (tab) {
        this.currentFilter = parseInt(tab);
      }
    },
    methods: {
      activateTab(index) {
        this.tabs.forEach((tab, tabIndex) => {
          tab.active = index === tabIndex;
        });
      },
      async fetchSurveys() {
        const response = await this.$request(`/v1/surveys`, "GET");
        this.surveys = response.data.data.items;
      },
      setFilter(tabIndex) {
        this.$router.push("/researchDefault?tab=" + tabIndex);
        this.currentFilter = tabIndex;
      },
      showJob(job) {
        this.selectedJob = job;
        this.showJobPopup = true;
      },

      triggerReset() {
        if (this.currentFilter === 1) {
          this.$refs.occupationTable.reset();
        } else if (this.currentFilter === 2) {
          this.$refs.newCollegeTable.reset();
        }
      },
    },
  };
</script>

<style scoped>
  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    height: 951px;
    position: relative;
    width: 865px;
  }

  .reset_button {
    color: #259b35;
    font-size: 15px;
    text-decoration: underline;
    margin-right: 30px;
    font-weight: 500;
    cursor: pointer;
    display: block;
  }

  @media all and (max-width: 600px) {
    .overlap-HomeFeedStudentDefaultgroup1 {
      border-radius: 30px;
      min-height: 951px;
      position: relative;
      width: 865px;
      margin-top: 80px;
    }
  }

  .frame-2342 {
    align-items: flex-start;
    background-color: var(--white);
    border-radius: 30px;
    box-shadow: 0px 0px 15px #0000000d;

    gap: 10px;
    width: 100%;
    left: 0;
    overflow: hidden;
    padding: 50px 28px;
    position: absolute;
    top: 0;
  }

  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    height: 100vh;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 1512px;
    width: 100%;
  }

  @media all and (max-width: 900px) {
    .home-feed-student-default {
      align-items: flex-start;
      background-color: #f3f7f8;
      border: 1px none;
      display: flex;
      gap: 25px;
      height: 982px;
      overflow: hidden;
      padding: 0 18px;
      position: relative;
      max-width: 100vw !important;
    }
  }

  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: relative;
    right: 0%;
    max-width: 291px;
  }

  @media all and (max-width: 1400px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  @media all and (max-width: 600px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 120px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  .frame-2354 {
    align-items: center;
    display: flex;
    margin-right: 12px;
    min-width: 109px;
  }

  .bell-1 {
    height: 20px;
    width: 20px;
  }

  .cog-1 {
    height: 20px;
    margin-left: 10px;
    width: 20px;
  }

  .overlap-group {
    align-items: flex-start;

    border: 1px solid;
    border-color: var(--celeste);
    border-radius: 23px;
    display: flex;
    height: 46px;
    margin-left: 13px;
    min-width: 46px;
    padding: 10px 11px;
  }

  .jj {
    color: var(--x475f4a);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    min-height: 24px;
    white-space: nowrap;
  }

  .broadcast-feed {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    height: 1479px;
    width: 100%;
  }

  .posts-feeed::-webkit-scrollbar {
    display: none;
  }

  .chat {
    width: 100%;
    min-height: 700px;
    display: flex;
    align-items: flex-start;

    gap: 20px;
    justify-content: space-between;
  }

  .chat_users {
    width: 30%;
    background: white;
    height: 100%;
    min-height: 700px;
    max-height: 700px;
    border-radius: 30px;
    padding: 40px 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }

  .chat_main {
    width: 100%;
  }

  .chat_user_heading {
    height: 50px;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .chat_user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    gap: 8px;
    line-height: normal;
  }

  .message_headings_icons {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .message_headings_icons_search_bar {
    height: 30px;
    width: 30px;
    background: #259b3521;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #259b35;
  }

  .message_headings_icons_search_bar:nth-child(2) {
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
  }

  .user_heading {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    margin-top: 30px;
    gap: 8px;
    padding-bottom: 10px;
    border-bottom: 1px solid #259b3521;
  }

  .user_heading img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }

  .user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .user_heading p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .scrollable {
    margin-top: 30px;
    max-height: 570px;
    overflow-y: scroll;
  }

  .chat_body {
    width: 70%;
    min-height: 700px;
    border-radius: 30px;
    background: white;
    height: 100%;
    padding: 40px 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }

  .chat_body_user_heading {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 30px;
    gap: 8px;
    padding-bottom: 10px;
  }

  .chat_body_user_heading img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  .chat_body_user_heading h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .chat_body_user_heading p {
    color: #ccc;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .chat_body_user_heading p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .chat_body_main {
    max-height: 470px;
    min-height: 470px;
    border-radius: 40px;
    background: #f3f7f8;
    width: 100%;
    margin-top: 10px;
    overflow-y: scroll;
  }

  .chat_form {
    width: 100%;
    height: 58px;
    background-color: white;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    border-radius: 10px;
  }

  .chat_form input {
    width: 90%;
    height: 100%;
    border-radius: 100px;
    padding: 0px 20px;
    border: 1px solid transparent;
    background-color: transparent;
  }

  .chat_form .send_message {
    height: 100%;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chat_form .send_message button {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-shrink: 0;
    justify-content: center;

    align-items: center;
    border: 1px solid transparent;
    border-radius: 100px;
    background: rgba(37, 155, 53, 0.13);
  }

  .single_message {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
    margin-top: 15px;
  }

  .single_message img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .single_message .message_div {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-flex;
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: red;
    border-radius: 100px;
    border-radius: 100px;
    background: rgba(37, 155, 53, 0.13);
  }

  .chat_body_main {
    padding: 20px 20px;
  }

  .single_message_right {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    height: 40px;
  }

  .single_message_right img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .single_message_right .message_div {
    padding: 0px 20px;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-flex;
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: red;
    border-radius: 100px;
    background: #e4e4e4;
  }

  .cards {
    width: 100%;
    margin-top: 40px;
    max-height: 600px;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
  }

  .single_card_body {
    display: flex;
    width: 100%;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    gap: 10px;
  }

  .card_headings {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .card_headings img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }

  .card_headings h1 {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card_description p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
</style>
