import AK_scholarship from './ak_scholarship.json';
import AL_scholarship from './al_scholarship.json';
import AR_scholarship from './ar_scholarship.json';
import AZ_scholarship from './az_scholarship.json';
import CA_scholarship from './ca_scholarship.json';
import CO_scholarship from './co_scholarship.json';
import DE_scholarship from './de_scholarship.json';
import FL_scholarship from './fl_scholarship.json';
import GA_scholarship from './ga_scholarship.json';
import ID_scholarship from './id_scholarship.json';
import IL_scholarship from './il_scholarship.json';
import IN_scholarship from './in_scholarship.json';
import IS_scholarship from './is_scholarship.json';
import KS_scholarship from './ks_scholarship.json';
import KY_scholarship from './ky_scholarship.json';
import LA_scholarship from './la_scholarship.json';
import MA_scholarship from './ma_scholarship.json';
import MD_scholarship from './md_scholarship.json';
import ME_scholarship from './me_scholarship.json';
import MI_scholarship from './mi_scholarship.json';
import MN_scholarship from './mn_scholarship.json';
import MO_scholarship from './mo_scholarship.json';
import MS_scholarship from './ms_scholarship.json';
import MT_scholarship from './mt_scholarship.json';
import NC_scholarship from './nc_scholarship.json';
import ND_scholarship from './nd_scholarship.json';
import NE_scholarship from './ne_scholarship.json';
import NH_scholarship from './nh_scholarship.json';
import NJ_scholarship from './nj_scholarship.json';
import NM_scholarship from './nm_scholarship.json';
import NV_scholarship from './nv_scholarship.json';
import NY_scholarship from './ny_scholarship.json';
import OH_scholarship from './oh_scholarship.json';
import OK_scholarship from './ok_scholarship.json';
import OR_scholarship from './or_scholarship.json';
import PA_scholarship from './pa_scholarship.json';
import RI_scholarship from './ri_scholarship.json';
import SC_scholarship from './sc_scholarship.json';

export default [
    {
        state_code:"AK",
        json: AK_scholarship
    },
    {
        state_code:"AL",
        json: AL_scholarship
    },
    {
        state_code:"AR",
        json: AR_scholarship
    },
    {
        state_code:"AZ",
        json: AZ_scholarship
    },
    {
        state_code:"CA",
        json: CA_scholarship
    },
    {
        state_code:"CO",
        json: CO_scholarship
    },
    {
        state_code:"DE",
        json: DE_scholarship
    },
    {
        state_code:"FL",
        json: FL_scholarship
    },
    {
        state_code:"GA",
        json:  GA_scholarship
    },
    {
        state_code:"ID",
        json: ID_scholarship
    },
    {
        state_code:"IL",
        json: IL_scholarship
    },
    {
        state_code:"IN",
        json: IN_scholarship
    },
    {
        state_code:"IS",
        json: IS_scholarship
    },
    {
        state_code:"KS",
        json: KS_scholarship
    },
    {
        state_code:"KY",
        json: KY_scholarship
    },
    {
        state_code:"LA",
        json: LA_scholarship
    },
    {
        state_code:"MA",
        json: MA_scholarship
    },
    {
        state_code:"MD",
        json:  MD_scholarship
    },
    {
        state_code:"ME",
        json: ME_scholarship
    },
    {
        state_code:"MI",
        json: MI_scholarship
    },
    {
        state_code:"MN",
        json: MN_scholarship
    },
    {
        state_code:"MO",
        json: MO_scholarship
    },
    {
        state_code:"MS",
        json: MS_scholarship
    },
    {
        state_code:"MT",
        json: MT_scholarship
    },
    {
        state_code:"NC",
        json: NC_scholarship
    },
    {
        state_code:"ND",
        json: ND_scholarship
    },
    {
        state_code:"NE",
        json: NE_scholarship
    },
    {
        state_code:"NH",
        json: NH_scholarship
    },
    {
        state_code:"NJ",
        json: NJ_scholarship
    },
    {
        state_code:"NM",
        json: NM_scholarship
    },
    {
        state_code:"NV",
        json: NV_scholarship
    },
    {
        state_code:"NY",
        json: NY_scholarship
    },
    {
        state_code:"OH",
        json: OH_scholarship
    },
    {
        state_code:"OK",
        json: OK_scholarship
    },
    {
        state_code:"OR",
        json: OR_scholarship
    },
    {
        state_code:"PA",
        json: PA_scholarship
    },
    {
        state_code:"RI",
        json: RI_scholarship
    },
    {
        state_code:"SC",
        json: SC_scholarship
    },
]