<template>
  <PortfolioLayout>
    <!-- School Data -->
    <template #school-courseHistoy>
      <Transcript />
    </template>
    <template #school-gpa>
      <Marks />
    </template>

    <!-- Quesionnaire -->
    <template #ques-career>
      <div class="grid grid-cols-12  text-[15px] my-4 mb-10">
        <div class="col-span-12 xl:col-span-3 text-lg font-medium">
          <div class="flex justify-between items-center xl:block">
            <span>Career Interests Scores</span>
            <div class="flex justify-end items-center gap-4 block xl:hidden">
              <button class="text-[#475F4A] rounded-3xl px-5 py-1.5 bg-[#F0F4F0] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <g clip-path="url(#clip0_190_1456)">
                    <path
                      d="M17.34 7.59L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.7575H9.75C8.5125 6.7575 7.5 7.77 7.5 9.0075V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.0075C6 6.9375 7.68 5.2575 9.75 5.2575H16.065L12.21 1.275C11.925 0.974996 11.925 0.502496 12.225 0.217496C12.525 -0.0675042 12.9975 -0.0675042 13.2825 0.232496L17.34 4.425C18.21 5.295 18.21 6.72 17.3325 7.5975L17.34 7.59ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9C4.5 7.53 5.1075 6.2025 6.0825 5.25H3.75C1.68 5.25 0 6.93 0 9V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                      fill="#475F4A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_190_1456">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span class="text-sm ml-2"> Share </span>

              </button>

              <button class="text-[#475F4A] rounded-3xl px-5 py-1.5 bg-[#F0F4F0] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                  <g clip-path="url(#clip0_189_1101)">
                    <path
                      d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                      fill="#475F4A" />
                  </g>
                  <defs>
                    <clipPath id="clip0_189_1101">
                      <rect width="16" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span class="text-sm ml-2"> Export </span>
              </button>
            </div>
          </div>
        </div>
        <p class="col-span-12 xl:col-span-6 text-[#A5AC9D] text-sm mt-4 mx-0 xl:-ml-16 xl:-mr-14 xl:mt-0">
          The scores below represent the level of interest in each career interest category based on how you answered
          the Career Interest Questionnaire.  A higher score means a greater interest in that particular career interest
          category.  Surveys can be retaken, and the scores in each career interest category may be changed.
        </p>
        <div class="col-span-6 xl:col-span-3 xl:block hidden">
          <div class="flex justify-end items-center gap-4">
            <button class="text-[#475F4A] rounded-3xl px-5 py-1.5 bg-[#F0F4F0] flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g clip-path="url(#clip0_190_1456)">
                  <path
                    d="M17.34 7.59L13.29 11.775C13.14 11.925 12.945 12 12.75 12C12.555 12 12.375 11.9325 12.225 11.79C11.925 11.505 11.9175 11.025 12.21 10.7325L16.065 6.7575H9.75C8.5125 6.7575 7.5 7.77 7.5 9.0075V12.7575C7.5 13.17 7.1625 13.5075 6.75 13.5075C6.3375 13.5075 6 13.17 6 12.7575V9.0075C6 6.9375 7.68 5.2575 9.75 5.2575H16.065L12.21 1.275C11.925 0.974996 11.925 0.502496 12.225 0.217496C12.525 -0.0675042 12.9975 -0.0675042 13.2825 0.232496L17.34 4.425C18.21 5.295 18.21 6.72 17.3325 7.5975L17.34 7.59ZM11.1825 12.8625C10.905 12.5925 9.78 11.1 9 10.05C9 10.05 9 11.55 9 12.66C9 13.77 8.265 14.745 7.2 14.955C5.76 15.2325 4.5 14.1375 4.5 12.75V9C4.5 7.53 5.1075 6.2025 6.0825 5.25H3.75C1.68 5.25 0 6.93 0 9V14.25C0 16.32 1.68 18 3.75 18H9C11.07 18 12.75 16.32 12.75 14.25V13.5C12.165 13.5 11.6025 13.275 11.1825 12.8625Z"
                    fill="#475F4A" />
                </g>
                <defs>
                  <clipPath id="clip0_190_1456">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span class="text-sm ml-2"> Share </span>
            </button>

            <button class="text-[#475F4A] rounded-3xl px-5 py-1.5 bg-[#F0F4F0] flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                <g clip-path="url(#clip0_189_1101)">
                  <path
                    d="M10.6667 3.3332C10.6667 3.16094 10.5972 2.99414 10.4722 2.87109L7.75278 0.191406C7.62778 0.0683594 7.45833 0 7.28056 0H7.11111V3.5H10.6667V3.3332ZM15.8611 8.42188L13.2028 5.78594C12.9222 5.50977 12.4417 5.70391 12.4417 6.09492V7.875H10.6639V9.625H12.4417V11.4078C12.4417 11.7988 12.9222 11.993 13.2028 11.7168L15.8611 9.07812C16.0444 8.89766 16.0444 8.60234 15.8611 8.42188ZM5.33333 9.1875V8.3125C5.33333 8.07187 5.53333 7.875 5.77778 7.875H10.6667V4.375H6.88889C6.52222 4.375 6.22222 4.07969 6.22222 3.71875V0H0.666667C0.297222 0 0 0.292578 0 0.65625V13.3438C0 13.7074 0.297222 14 0.666667 14H10C10.3694 14 10.6667 13.7074 10.6667 13.3438V9.625H5.77778C5.53333 9.625 5.33333 9.42813 5.33333 9.1875Z"
                    fill="#475F4A" />
                </g>
                <defs>
                  <clipPath id="clip0_189_1101">
                    <rect width="16" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span class="text-sm ml-2"> Export </span>
            </button>
          </div>
        </div>
      </div>
      <!--      <div class="map-container">-->
      <!--        <ul>-->
      <!--          <li class="label" v-for="(score, key) in scores" :key="key">-->
      <!--            <div class="stat-item">-->
      <!--              <div class="label">{{ key }}</div>-->
      <!--              :-->
      <!--              <div class="number">{{ score }}</div>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->

      <!--Bar Chart-->
      <div class="bg-white rounded-[50px] border border-gray-100 shadow p-10">
        <Bar :key="counter" width="100%" chartHeight="300px" :chartOptions="chartOptions" :series="chartSeries" />
      </div>

      <p class="text-[12px] text-[#A5AC9D] py-4 mt-5">
        Go to the Explore Tab, then click on the Occupations tab. The table will display the occupations that are good
        fits and best fits based on your career interest questionnaire results. You can filter the occupations by good
        fit and best fit.
      </p>
      <div class="bg-white rounded-[50px] border border-gray-100 shadow mb-10 p-10">
        <div class="grid grid-cols-12 gap-x-8 gap-y-4 text-[15px]">
          <!--Realistic-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium	mt-4 md:mt-0">Realistic</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Realistic interests like work that involves
            practical, hands-on problems and answers. They often do not like careers that involve paperwork or working
            closely with others. They like working with plants and animals, real-world materials like wood, tools, and
            machinery, and outside work.</div>
          <!--Investigative-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium mt-4 md:mt-0">Investigative</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Investigative interests like work that has
            to do with ideas and thinking rather than physical activity or leading people. They like searching for facts
            and figuring out problems.</div>
          <!--Artistic-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium mt-4 md:mt-0">Artistic</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Artistic interests like work that deals
            with the artistic side of things, such as acting, music, art, and design. They like creativity in their
            work, which can be done without following a set of rules.</div>
          <!--Social-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium mt-4 md:mt-0">Social</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Social interests like working with others
            to help them learn and grow. They like working with people more than working with objects, machines, or
            information. They enjoy teaching, giving advice, and helping and serving people.</div>
          <!--Enterprising-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium mt-4 md:mt-0">Enterprising</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Entrepreneurial interests like work that
            involves starting up and carrying out business projects. These people like taking action rather than
            thinking about things. They like persuading and leading people, making decisions, and taking risks for
            profits.</div>
          <!--Conventional-->
          <div class="col-span-12 md:col-span-2 md:text-right uppercase font-medium mt-4 md:mt-0">Conventional</div>
          <div class="col-span-12 md:col-span-10 text-[#475F4A]">People with Conventional interests like work that
            follows set procedures and routines. They prefer working with information and paying attention to details
            rather than working with ideas. They like working with clear rules and following a strong leader.</div>
        </div>
      </div>

      <!-- <div class="mb-10">
        <CareerJobTable />
      </div> -->
    </template>

    <template #ques-armed>
      <ArmedServices />
    </template>
    <template #ques-workforce>
      <Workforce />
    </template>

    <template #ques-college>
      <CollegeInterest />
    </template>

    <template #ques-learning />

    <template #ques-accomplishments />

    <template #ques-demographic />

    <!-- Saved Items -->
    <template #saved-occupations>
      <div class="wrap">Saved Occupations will add soon</div>
    </template>
    <template #saved-colleges>
      <div class="wrap">Saved colleges will add soon</div>
    </template>
    <template #saved-armed>
      <div class="wrap">Saved armed will add soon</div>
    </template>
    <template #saved-businesses>
      <div class="wrap">Saved businesses will add soon</div>
    </template>
    <template #saved-scholarships>
      <div class="wrap">Saved Scholarships will add soon</div>
    </template>
    <template #saved-jobs>
      <div class="wrap">Saved jobs will add soon</div>
    </template>

    <!-- Build and Plan -->
    <template #creations-academic>
      <FourYearsPlanDefault />
    </template>f
    <template #creations-postHigh>
      <div class="wrap">Post High School Goals will add soon</div>
    </template>
    <template #creations-resume>
      <ResumeDefault />
    </template>
  </PortfolioLayout>
</template>
<script setup>
import PortfolioLayout from "../layouts/PortfolioLayout.vue";
//School Data
import Transcript from "../components/Portfolio/SchoolData/Transcript.vue";
import Marks from "../components/Portfolio/SchoolData/Marks.vue";
//Quesionnaire
import ArmedServices from "../components/Portfolio/Quesionnaire/ArmedServices.vue";
import Workforce from "../components/Portfolio/Quesionnaire/WorkForce.vue";

//PlanBuild
import ResumeDefault from "../components/Portfolio/PlanBuild/ResumeDefault.vue";
import FourYearsPlanDefault from "../components/Portfolio/PlanBuild/FourYearsPlanDefault.vue";
import CareerJobTable from '../components/Tables/CareerJobTable.vue';
import CollegeInterest from '../components/Tables/CollegeInterest.vue';
import { onMounted, reactive, ref } from "vue";
import { getAssociatedData } from "../AssociatedData.js";
import Bar from "../components/Charts/Bar.vue";

const scores = ref({});
const counter = ref(0);

const chartOptions = reactive({
  chart: {
    id: 'vuechart-example',
    toolbar: {
      show: false // Hide toolbar
    }
  },
  colors: ['#259B35', '#952C90', '#999B25', '#330F3F', '#C57C0E', '#3086D5'],
  plotOptions: {
    bar: {
      endingShape: 'rounded',
      columnWidth: '35%',
      distributed: true,
    }
  },
  xaxis: {
    categories: [],
    labels: {
      formatter: function (val) {
        // Capitalize the first letter of each word
        return val?.charAt(0).toUpperCase() + val.slice(1);
      }
    },
    axisBorder: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  legend: {
    show: false
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false,
  },
  tooltip: {
    enabled: true
  }

});

const chartSeries = reactive([
  {
    name: 'Score',
    data: [],
  },
]);

onMounted(async () => {
  const assocData = await getAssociatedData();
  console.log('Portfolio.vue assocData: ', assocData);
  
  if (assocData?.typeFormScores) {
    scores.value = assocData.typeFormScores;
    chartOptions.xaxis.categories = Object.keys(scores.value);
    chartSeries[0].data = Object.values(scores.value);
  }
});

</script>
<style>
.stat-item {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  flex: 1;
}

.stat-item .label {
  font-size: 14px;
  color: #888;
  margin-right: 5px;
}

.stat-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  color: #4caf50;
  background-color: white;
  border-radius: 8px;
  min-width: 8px;
  flex: 1;
  min-width: 100px;
}

.map-container {
  height: 200px;
  margin-bottom: 1rem;
}

.map-container li {
  background-color: white;
  margin: 0.5rem 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  flex: 0 0 auto;
  display: inline-block;
}

.map-container ul {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.wrap {
  @apply w-full h-full flex items-center justify-center;
}
</style>
