<template>
  <div class="main-wrapper">
    <div class="w-full flex items-center justify-between mx-auto pt-5">
      <div>
        <router-link to="/educator/studentDemo/home" class="flex items-center gap-3">
          <img class="h-10" src="/src/assets/images/logo.svg" alt="SP Logo" />
        </router-link>
      </div>

      <div class="top-navigation-bar">
        <router-link v-for="link in navLinks" :key="link.name" :to="link.to" class="nav-link"
          :class="[isActive(link.to) ? 'parent_a' : '']">
          {{ link.name }}
        </router-link>

        <!-- <div v-if="dropdownLinks.resource" class="relative resource">
                    <div class="nav-link">
                        Resources
                        <img src="@/assets/images/arrow-down.svg" alt="" />
                    </div>
                    <ul class="dropdown resource-dropdown">
                        <li v-for="item in dropdownLinks.resource" :key="item.name" class="dropdown-item">
                            <router-link :to="item.to" class="link"
                                :class="[currentLink === item.to ? 'parent_a' : '']">
                                {{ item.name }}
                            </router-link>
                        </li>
                    </ul>
                </div> -->
      </div>
      <div class="demoAndProfile">
        <router-link to="/educator/home" @click.native="studentDemoHandlerFalse">
          <button>Back to Educator</button>
        </router-link>
      </div>

      <div class="user-wrap cursor-pointer">
        <div class="logout-popup">
          <button class="btn px-2 py-1.5 text-sm font-medium rounded-md w-full text-center" @click="logoutNow">
            Logout
          </button>
        </div>
        <img src="/src/assets/images/educatordummmyProfile.svg" alt="user" class="w-10 h-10" />
        <h3 class="text-sm font-semibold">Maia Educator</h3>
      </div>
    </div>

    <div class="w-full h-full grid grid-cols-1 grid-rows-1 mt-5 overflow-hidden">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStudentDemo } from "../store/StudentDemoStates";

const navLinks = [
  { name: "Home", to: "/educator/studentDemo/home" },
  { name: "Discover", to: "/educator/studentDemo/surveyDefault" },
  { name: "Explore", to: "/educator/studentDemo/researchDefault" },
  { name: "Portfolio", to: "/educator/studentDemo/portfolio" },
  { name: "Messages", to: "/educator/studentDemo/messages" },
  { name: "Resources", to: "" },
];

const store = useStudentDemo();
const router = useRouter();

const isActive = (routePath) => {
  return router.currentRoute.value.path === routePath;
};

const studentDemoHandlerFalse = () => {
  store.setIsStudentDemo(false);
};
const logoutNow = () => {
  localStorage.clear();
  window.location.href = "/";
};
</script>

<style scoped>
.main-wrapper {
  @apply w-full max-w-[1280px] h-full mx-auto px-5 m-0 flex flex-col items-center justify-center;
}

.top-navigation-bar {
  background-color: var(--white);
  box-shadow: 0px 0px 12px #0000000d;
  height: 60px;
  @apply rounded-full px-6 py-3 flex items-center gap-2.5;
}

.demoAndProfile {
  display: flex;
  align-items: center;
  gap: 50px;
}

.demoAndProfile button {
  background-color: #259B35;
  color: #fff;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 100px;
}

.dropdown {
  @apply absolute left-3 top-7 w-60 rounded-xl border border-gray-200 bg-white z-[99999] shadow-lg hidden overflow-hidden;
}

.dropdown-item {
  @apply text-sm border-b border-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-600 cursor-pointer;
}

.dropdown-item a,
.dropdown-item .link {
  @apply w-full h-full inline-block px-3 py-2;
}

.nav-link {
  @apply rounded-full flex items-center justify-center gap-2 px-5 py-2.5 text-sm text-gray-700 hover:text-primary cursor-pointer relative;
}

.parent_a {
  @apply bg-primary-light text-primary font-semibold;
}

.active {
  color: #259b35 !important;
}

.discover:hover .discover-dropdown {
  @apply inline-block;
}

.portfolio:hover .portfolio-dropdown {
  @apply inline-block;
}

.plan:hover .plan-dropdown {
  @apply inline-block;
}

.resource:hover .resource-dropdown {
  @apply inline-block;
}

.info-values {
  @apply text-sm font-semibold text-primary;
}

.user-wrap {
  @apply flex items-center gap-5 relative;
}

.logout-popup {
  @apply w-32 rounded-lg bg-white absolute left-10 top-10 shadow p-2 hidden;
}

.user-wrap:hover .logout-popup {
  @apply block;
}
</style>
