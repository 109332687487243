<template>
  <div class="broadcast-feed">
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center justify-center gap-4">
        <div v-for=" (filter, index) in filters" :key="filter" :class="[
          index === currentFilter
            ? 'all text-white bg-[#259b35] px-8 py-1 text-sm rounded-full inter-medium-white-20px'
            : ' px-8 py-1 text-sm rounded-full text-white text-[20px] bg-[#A5AC9D]',
          'cursor-pointer all inter-medium-white-20px',
        ]" @click="setFilter(index)">
          {{ filter }}
        </div>
      </div>
      <button class="prentalControl" v-if="isParent" @click="clickParentControlButton">Parental Controls</button>
    </div>
    <div v-if="isloading">
      loading data
    </div >
    <div v-else>

      <div class="posts-feeed" v-if="currentFilter === 0">
        <BroadcastPost v-for="post in broadcastFeeds" :key="`${post.id}`" :post="post" :saved="checkSaved(post.id)"
                       @save="updateSavedFeed" />
      </div>

      <div class="posts-feeed" v-else-if="currentFilter === 1">
        <BroadcastPost v-for="post in savedPosts" :key="`${post.id}`" :post="post" :saved="true" @save="updateSavedFeed" />
      </div>

    </div>


  </div>
</template>

<script>
  import { getAssociatedData, saveAssociatedData, updateAssociatedData } from "../AssociatedData";
  import BroadcastPost from "./BroadcastPost.vue";
  import { useStudentDemo } from "../store/StudentDemoStates";

  export default {
    name: "BroadcastFeed",
    components: {
      BroadcastPost,
    },
    props:{
      isParent:{type:Boolean,default:false}
    },
    data() {
      return {
        isloading: true,
        selectedJob: "",
        selectedScholarship: "",
        showJobPopup: false,
        showScholarshipPopup: false,
        savedPosts: [],
        error: null,
        filters: ["All", "Saved"],
        currentFilter: 0,
        broadcastFeeds: [],
        store: null,
        savedBroadcasts: [],
      };
    },
    mounted() {
      const store = useStudentDemo();
      this.store = store;
      this.fetchData();
    },
    methods: {
      setFilter(tabIndex) {
        this.currentFilter = tabIndex;
      },
      async fetchData() {
        try {
          const ad = await getAssociatedData();

          this.savedPosts = ad?.savedBroadcasts || [];
          const response = await this.$request("/v1/all/broadcast", "GET");
          this.broadcastFeeds = response.data.data;

          const doesInfoPreferencesExist = ad.broadcastsInformation.length > 0 && ad.broadcastsInformation;

          const doesOpportunitiesPreferencesExist = ad.broadcastsOpportunities.length > 0 && ad.broadcastsInformation;

          if (doesInfoPreferencesExist && doesOpportunitiesPreferencesExist) {
            this.broadcastFeeds = this.broadcastFeeds.filter(element =>
              ad.broadcastsInformation.includes(element.broadcast_type) ||
              ad.broadcastsOpportunities.includes(element.broadcast_type)
            );


          } else {
            if (doesInfoPreferencesExist) {
              this.broadcastFeeds = this.broadcastFeeds.filter(element =>
                ad.broadcastsInformation.includes(element.broadcast_type)
              );
            }

            if (doesOpportunitiesPreferencesExist) {
              this.broadcastFeeds = this.broadcastFeeds.filter(element =>
                ad.broadcastsOpportunities.includes(element.broadcast_type)
              );
            }
          }


        } catch (err) {
          console.log(err);
        }
        finally {
          this.isloading = false;
        }
      },
      async updateSavedFeed(id) {
        const toAdd = this.broadcastFeeds.find((el) => el.id === id);
        if (this.store.isStudentDemo) {
          const isSaved = this.savedBroadcasts.some((savedBroadcast) => savedBroadcast.id === id);

          if (isSaved) {
            this.savedBroadcasts = this.savedBroadcasts.filter((savedBroadcast) => savedBroadcast.id !== id);
          } else {
            this.savedBroadcasts.push({ ...toAdd });
          }

          this.savedPosts = this.savedBroadcasts;
        } else {
          const ad = await getAssociatedData();

          if (ad != null) {
            let isSaved =
              ad.savedBroadcasts != null &&
              ad.savedBroadcasts.length > 0 &&
              ad.savedBroadcasts.some((savedBoradcast) => savedBoradcast.id === id);

            if (isSaved) {
              ad.savedBroadcasts = ad.savedBroadcasts.filter((savedBoradcast) => savedBoradcast.id !== id);
            } else {
              ad.savedBroadcasts != null && ad.savedBroadcasts.length > 0
                ? ad.savedBroadcasts.push({ ...toAdd })
                : (ad.savedBroadcasts = [{ ...toAdd }]);
            }

            const res = await updateAssociatedData(ad);
            this.savedPosts = res.data.data.savedBroadcasts;
          } else {
            const ad = {};
            ad.savedBroadcasts = [{ ...toAdd }];
            const res = await saveAssociatedData(ad);
            this.savedPosts = res.data.data.savedBroadcasts;
          }
        }
      },
      checkSaved(id) {
        let savedFeeds = this.savedPosts;
        if (savedFeeds && savedFeeds.length > 0) {
          const index = savedFeeds.findIndex((el) => el.id === id);
          return index > -1;
        }
        return false;
      },
      clickParentControlButton() {
        this.$emit('clickParentControlButton')
      }
    },
  };
</script>

<style scoped>
  .broadcast-feed {
    @apply w-full h-full bg-white rounded-2xl px-5 py-10 flex flex-col items-center justify-center gap-5;
  }

  .posts-feeed::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  .posts-feeed {
    @apply w-full h-full min-h-full overflow-y-scroll inline-flex flex-col items-start gap-7 relative;
  }

  .feed_card {
    border-radius: 20px;
    border: 0.5px solid #ccc;
    background: #fff;
    padding: 30px 20px;
    width: 100%;
  }

  .headings_card {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .heading_image img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .heading_texts h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .heading_texts p {
    color: #ccc;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-top: 2px;
    line-height: normal;
  }

  .paragraph_card {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .paragraph_card p {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .card_buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card_buttons .link {
    padding: 10px 20px !important;
    border-radius: 100px;
    background: #259b35 !important;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    display: block;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;
    margin-left: auto;
  }

  .card_social {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 20px;
    justify-content: flex-end;
  }

  @media all and (max-width: 800px) {
    .tags {
      z-index: 9999;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      flex-direction: column;
    }
  }

  .inter-medium-white-20px {
    font-family: var(--font-family-inter) !important;

    font-style: normal !important;
    font-weight: 500 !important;
  }

  .prentalControl {
    padding: 13px 17px;
    background-color: #475f4a;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100px;
  }
</style>
