<template>
  <div class="login-box">
    <div class="email-address-box">
      <div class="email-address inter-medium-gray-asparagus-15px">
        {{ emailAddress }}
      </div>
      <div class="overlap-LoginBox_gr-3">
        <img class="envelope-square-1"
          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64b72ee926d03ced7977547a/img/envelope-square-1.svg"
          alt="envelope-square 1" />
        <input v-model="emailValue" class="emailexamplecom" name="emailexamplecom" :placeholder="inputPlaceholder1"
          :type="inputType1" required />
      </div>
    </div>
    <div class="password-box">
      <div class="passwords inter-medium-gray-asparagus-15px">
        {{ passwords }}
      </div>
      <div class="overlap-LoginBox_gr1-2">
        <img class="lock-alt-1"
          src="https://anima-uploads.s3.amazonaws.com/projects/64aefc8d0c7187cefb1b74e6/releases/64bf07089c71654b57c5b973/img/lock-alt-1.svg"
          alt="lock-alt 1" />
        <input v-model="password" class="password" name="password" :placeholder="inputPlaceholder2" :type="inputType2"
          required />
      </div>
    </div>
    <div class="forgot-password">
      {{ forgotPassword }}
    </div>
    <p class="display-msg new_error display-error"></p>
    <a href="javascript:SubmitForm('form1')" @click="loginMYSQL">
      <div class="login-button">
        <div class="overlap-LoginBox_gr2-1">
          <div class="login">{{ login }}</div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
  import {
    edLinkLogin,
    storeUser,
    navigateToHomePageByRole,
    handleAssociatedData,
    getTopRole,
    getToken,
    storeScholarPathUser,
    showError,
    clearError, storeMongoUser,
  } from "../auth.js";

export default {
  name: "LoginBox",
  props: [
    "emailAddress",
    "inputType1",
    "inputPlaceholder1",
    "passwords",
    "inputType2",
    "inputPlaceholder2",
    "forgotPassword",
    "login",
  ],
  data() {
    return {
      emailValue: "",
      password: "",
      daResponse: [],
    };
  },
  methods: {
    async loginMYSQL() {

      const payLoad = {
        email: this.emailValue,
        password: this.password,
      };
      let user;

        try {
            // try database sign in
            // still need edlink data
            const signInResp = await this.$request("/v1/signin", "POST", payLoad);
            const usr = signInResp.data.data.user;
            if (signInResp.status === 200) {
                storeMongoUser(signInResp);
                if (usr && usr.userType == "student") {
                  const edlinkPersonByEmailResp = await this.$request("/v1/personByEmail", "POST", payLoad);
                  user = storeUser(edlinkPersonByEmailResp);
                  await getToken(user.email, user.id, this.$request)
                  await handleAssociatedData(user, this.$request, (role) => navigateToHomePageByRole(role, this.$router));
                } else {
                  await getToken(usr.email, usr.id, this.$request);
                  navigateToHomePageByRole(usr.userType, this.$router);
                }

            } else {
                user = await edLinkLogin(false, payLoad, this.$request, storeUser, (role) =>
                    navigateToHomePageByRole(role, this.$router)
                );
            }
        } catch (error) {
            console.error(error)
            await this.scholarPathLogin(payLoad);
        }
    },
    async scholarPathLogin(user) {
      let scholarUser = null;
      console.log('scholarPathLogin()');

      try {
        scholarUser = await this.dbLogin(user);
      } catch (error) { }

      if (!scholarUser || Object.keys(scholarUser).length === 0) {
      } else {
        await getToken(scholarUser.email, scholarUser.id, this.$request);
        storeScholarPathUser(scholarUser);
        this.navigateToUserRoleHomePage(scholarUser);
      }
    },
    async dbLogin(payLoad) {
      let user = null;
      const response = await this.$request("/v1/login_mysql", "POST", payLoad);
      this.daResponse = response.data;
      if (this.daResponse && this.daResponse.data && this.daResponse.data[0]) {
        user = this.daResponse.data[0];
      } else {
      }
      return user;
    },
    navigateToUserRoleHomePage(user) {
      // roles
      // admin = 0, provider = 1, educator = 2, parent = 3, student = 4
      if (user && user.admin && user.admin == 1) {
        // admin
        this.$router.push("/admin/home");
      } else if (user && user.role == 1) {
        // provider
        this.$router.push("/provider/feed");

      } else if (user && user.is_educator && user.is_educator == 1) {
        // educator
        this.$router.push("/educator/home");
      } else if (user && user.role == 3) {
        // parent
        this.$router.push("/homeFeed");
      } else {
        // student
        this.$router.push("/homeFeed");
      }
    },
  },
};
</script>

<style scoped>
.login-box {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 31px;
  min-height: 253px;
  width: 384px;
}

.email-address-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: -4px;
  min-height: 63px;
  width: 388px;
}

.email-address,
.passwords {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 10px;
  min-height: 18px;
  white-space: nowrap;
}

.overlap-LoginBox_gr-3 {
  align-items: center;
  background-color: var(--black-haze);
  border-radius: 5px;
  display: flex;
  gap: 28px;
  height: 40px;
  min-width: 384px;
  padding: 0 10px;
  border: 1px solid transparent;
}

.input_errorborder {
  border: 1px solid #af4242;
}

.envelope-square-1 {
  height: 26px;
  width: 23px;
}

.emailexamplecom {
  background-color: transparent;
  border: 0;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 6px;
  padding: 0;
  white-space: nowrap;
  width: 100%;
}

.emailexamplecom::placeholder,
.password::placeholder {
  color: #cccccc99;
}

.password-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: -4px;
  margin-top: 30px;
  min-height: 63px;
  width: 388px;
}

.overlap-LoginBox_gr1-2 {
  align-items: center;
  background-color: var(--black-haze);
  border-radius: 5px;
  display: flex;
  gap: 29px;
  height: 40px;
  min-width: 384px;
  padding: 0 10px;
}

.lock-alt-1 {
  height: 26px;
  width: 22px;
}

.password {
  background-color: transparent;
  border: 0;
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 6px;
  padding: 0;
  white-space: nowrap;
  width: 311px;
}

.forgot-password {
  color: var(--style);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 15px;
  min-width: 96px;
}

.login-button {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  margin-right: -2px;
  margin-top: 21px;
  min-width: 386px;
  opacity: 0.5;
}

.overlap-LoginBox_gr2-1 {
  align-items: center;
  background-color: var(--style);
  border-radius: 5px;
  display: flex;
  height: 52px;
  min-width: 384px;
  padding: 0 166px;
  width: 100%;
}

.login {
  color: var(--white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 52px;
  white-space: nowrap;
}

.new_error {
  color: #af4242;
  display: none;
  width: 100%;
  margin-top: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-top: 1px;
}

.new_error.display-error {
  display: block;
}
</style>
