<template>
  <div :style="{ width: width }"
    class="max-w-sm border rounded-full px-4 py-2 flex items-center overflow-hidden transition-all duration-150"
    :class="[{ 'border-green-600': focus }]">
    <div class="flex items-center justify-center rounded-full bg-[var(--style-percent-13)]">
      <img src="@/assets/images/searchgreen.png" alt="search" class="cursor-pointer" />
    </div>
    <div class="ml-4 flex-grow">
      <input type="text" class="w-full h-full bg-white outline-none text-sm py-1" 

      :placeholder="placeholder"
      v-model="val"
      @input="$emit('update', val)"
      @focusin="focus = true"
      @focusout="focus = false"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  placeholder: String,
  width: {
    type: String,
    default: '274px', 
  },
});

const val = ref("");
const focus = ref(false);

defineOptions({
  name: "SearchBar",
});
</script>

<style></style>
