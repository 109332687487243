import { createRouter, createWebHashHistory } from "vue-router";

import ClusterPathWay from "../components/EducatorPages/ClusterPathWay.vue";
import HomeFeedEducatorDefault from "../components/EducatorPages/HomeFeedEducatorDefault.vue";
import HomeFeedStudentDefault from "../components/HomeFeedStudentDefault.vue";
import StudentLoginPage from "../components/StudentLoginPage.vue";
import { homeFeedStudentDefaultData } from "../dataHomeFeedStudentDefault";
import { studentLoginPageData } from "../dataStudentLogin";

import Message from "../components/Messages.vue";
import SurveyDefault from "../components/SurveyDefault.vue";

import CareerDefault from "../components/CareerDefault.vue";
// import FourYearsPlanDefault from '../components/FourYearsPlanDefault.vue';
import ProviderBroadcast from "../components/ProviderPages/ProviderBroadcast.vue";
import ProviderProfile from "../components/ProviderPages/ProviderProfile.vue";
import SingleBroadcast from "../components/ProviderPages/SingleBroadcast.vue";
import ResearchDefault from "../components/ResearchDefault.vue";
import PasswordReset from "../components/PasswordReset.vue";
// import ResumeDefault from '../components/ResumeDefault.vue';
import College from "../components/College/index.vue";
import Occupation from "../components/Occupation/index.vue";
import Portfolio from "../pages/Portfolio.vue";

// all layouts
import Admin from "../layouts/admin.vue";
import auth from "../layouts/auth.vue";
import main from "../layouts/default.vue";
import Educator from "../layouts/educator.vue";
import ParentOne from "../layouts/Parent/parent-one.vue";
import ParentTwo from "../layouts/Parent/parent-two.vue";
import ProviderOne from "../layouts/Provider/provider-one.vue";
import ProviderThree from "../layouts/Provider/provider-three.vue";
import ProviderTwo from "../layouts/Provider/provider-two.vue";
import StudentDemoLayout from "../layouts/studentDemoLayout.vue";

import ProviderLayout from "../layouts/ProviderLayout.vue";
import ProviderPortfolioForStudent from "../components/Portfolio/Provider/ProviderPortfolioForStudent.vue";

// imports for student demo pages start

import studentDemoHome from "../pages/studentDemo/home.vue";
import studentDemoSurveyDefault from "../pages/studentDemo/surveyDefault.vue";
import studentDemoResearchDefault from "../pages/studentDemo/researchDefault.vue";
import studentDemoPortfolio from "../pages/studentDemo/portfolio.vue";
import studentDemoMessages from "../pages/studentDemo/messages.vue";
import ScholarPathAdmin from "../layouts/ScholarPathAdmin.vue";

// imports for student demo pages end

const routes = [
  // start  new pages
  {
    path: "/reset-password",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      layout: main,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../pages/index.vue"),
    meta: {
      layout: main,
    },
  },

  // for Provider user path
  {
    path: "/provider/feed",
    name: "providerFeed",
    component: () => import("../pages/Provider/home-feed.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/provider/broadcasts",
    name: "Broadcast",
    component: () => import("../pages/Provider/broadcast.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/provider/messages",
    name: "messagesOne",
    component: () => import("../pages/Provider/message-one.vue"),
    meta: {
      layout: main,
    },
  },
  {
    path: "/provider/broadcast-details/:id",
    name: "BroadcastDetails",
    component: () => import("../pages/Provider/broadcast-details.vue"),
    meta: {
      layout: ProviderThree,
    },
  },
  {
    path: "/provider/profile",
    name: "ProfilePage",
    component: () => import("../pages/Provider/profile.vue"),
    meta: {
      layout: main,
    },
  },

  // start coding for education pages

  {
    path: "/educator/home",
    name: "EducatorPage",
    component: () => import("../pages/Educator/home.vue"),
    meta: {
      layout: Educator,
    },
  },
  {
    path: "/educator/pathway",
    name: "PathwayPage",
    component: () => import("../pages/Educator/pathway.vue"),
    meta: {
      layout: Educator,
    },
  },
  {
    path: "/educator/insights",
    name: "InsightsPage",
    component: () => import("../pages/Educator/insights.vue"),
    meta: {
      layout: Educator,
    },
  },
  {
    path: "/educator/broadcasts",
    name: "BroadCasts",
    component: () => import("../pages/Educator/Broadcasts.vue"),
    meta: {
      layout: Educator,
    },
  },

  // for parent view

  {
    path: "/parent/broadcast",
    name: "ParentBroadcast",
    component: () => import("../pages/Parent/broadcast.vue"),
    meta: {
      layout: ParentOne,
    },
  },
  {
    path: "/parent/messages",
    name: "ParentMessages",
    component: () => import("../pages/Parent/messages.vue"),
    meta: {
      layout: ParentTwo,
    },
  },
  // start coding for admin route
  {
    path: "/admin/home",
    name: "AdminHome",
    component: () => import("../pages/Admin/home.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/providers",
    name: "AdminProviders",
    component: () => import("../pages/Admin/Providers.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/broadcasts",
    name: "AdminBroadcasts",
    component: () => import("../pages/Admin/Broadcasts.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/resources",
    name: "AdminResources",
    component: () => import("../pages/Admin/home.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/insights",
    name: "AdminInsights",
    component: () => import("../pages/Admin/home.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/settings",
    name: "AdminSetting",
    component: () => import("../pages/Admin/settings.vue"),
    meta: {
      layout: Admin,
    },
  },
  {
    path: "/admin/cluster/:id",
    name: "AdminCluster",
    component: () => import("../pages/Admin/cluster.vue"),
    meta: {
      layout: Admin,
    },
  },
  // scholarpath admin route
  {
    path: "/scholarpath-admin/home",
    name: "ScholarPathAdminHome",
    component: () => import("../pages/ScholarpathAdmin/home.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },
  {
    path: "/scholarpath-admin/broadcasts",
    name: "ScholarPathAdminBroadcasts",
    component: () => import("../pages/ScholarpathAdmin/Broadcasts.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },
  {
    path: "/scholarpath-admin/resources",
    name: "ScholarPathAdminResources",
    component: () => import("../pages/ScholarpathAdmin/Resources.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },
  {
    path: "/scholarpath-admin/insights",
    name: "ScholarPathAdminInsights",
    component: () => import("../pages/ScholarpathAdmin/Insights.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },
  {
    path: "/scholarpath-admin/settings",
    name: "ScholarPathAdminSetting",
    component: () => import("../pages/ScholarpathAdmin/Settings.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },
  {
    path: "/scholarpath-admin/cluster/:id",
    name: "ScholarPathAdminCluster",
    component: () => import("../pages/Admin/cluster.vue"),
    meta: {
      layout: ScholarPathAdmin,
    },
  },

  //  old page from dev branch
  {
    path: "/:catchAll(.*)",
    name: "AuthLogin",
    component: StudentLoginPage,
    props: { ...studentLoginPageData },
    meta: { title: "ScholarPath", layout: auth },
  },

  {
    path: "/homeFeed",
    name: "HomeFeedStudentDefault",
    component: HomeFeedStudentDefault,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/portfolio/:id",
    name: "ProviderPortfolioForStudent",
    component: ProviderPortfolioForStudent,
    props: {
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/educatorHomeFeed",
    name: "HomeFeedEducatorDefault",
    component: HomeFeedEducatorDefault,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/clusterPathway",
    name: "ClusterPathWay",
    component: ClusterPathWay,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/surveyDefault",
    name: "SurveyDefault",
    component: SurveyDefault,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },

  {
    path: "/messages",
    name: "Message",
    component: Message,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/college/:id",
    name: "College",
    component: College,
    props: {
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
    },
    meta: {
      layout: main,
    },
  },
  {
    path: "/occupation/:id",
    name: "Occupation",
    component: Occupation,
    props: {
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
    },
    meta: {
      layout: main,
    },
  },

  {
    path: "/researchDefault",
    name: "ResearchDefault",
    component: ResearchDefault,
    props: {
      jj: "JJ",
      leftNavigationBarProps: homeFeedStudentDefaultData.leftNavigationBarProps,
      topNavigationBarProps: homeFeedStudentDefaultData.topNavigationBarProps,
      broadcastFeedProps: homeFeedStudentDefaultData.broadcastFeedProps,
      careerProfileFrameProps: homeFeedStudentDefaultData.careerProfileFrameProps,
    },
    meta: {
      layout: main,
    },
  },

  {
    path: "/careersDefault",
    name: "CareerDefault",
    component: CareerDefault,
    meta: {
      layout: main,
    },
  },
  {
    path: "/providerBroadcast",
    name: "ProviderBroadcast",
    component: ProviderBroadcast,
    meta: {
      layout: main,
    },
  },
  {
    path: "/singleBroadcast",
    name: "SingleBroadcast",
    component: SingleBroadcast,
    meta: {
      layout: main,
    },
  },
  {
    path: "/providerProfile",
    name: "ProviderProfile",
    component: ProviderProfile,
    meta: {
      layout: main,
    },
  },
  {
    path: "/portfolio",
    component: Portfolio,
    name: "Portfolio",
    meta: {
      layout: main,
    },
  },

  // book student demo from educator
  {
    path: "/educator/studentDemo/home",
    name: "homepageForStudentDemoByEducator",
    component: studentDemoHome,
    meta: {
      layout: main,
    },
  },
  {
    path: "/educator/studentDemo/surveyDefault",
    name: "surveyDefaultForStudentDemByEducator",
    component: studentDemoSurveyDefault,
    meta: {
      layout: main,
    },
  },
  {
    path: "/educator/studentDemo/researchDefault",
    name: "researchDefaultForStudentDemoByEducator",
    component: studentDemoResearchDefault,
    meta: {
      layout: main,
    },
  },
  {
    path: "/educator/studentDemo/portfolio",
    name: "portfolioForStudentDemoByEducator",
    component: studentDemoPortfolio,
    meta: {
      layout: main,
    },
  },
  {
    path: "/educator/studentDemo/messages",
    name: "messagesForStudentDemoByEducator",
    component: studentDemoMessages,
    meta: {
      layout: main,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
