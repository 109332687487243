<template>
  <div class="wrapper">
    <div class="h-[10%] flex items-center justify-between pb-4 bg-white z-40">
      <div class="flex-1 flex items-center justify-between">
        <div></div>
        <!-- Placeholder for potential left-aligned content -->
        <div class="py-5">
          <button
            class="bg-white py-3 px-6 shadow-[0_0_10px_0_rgba(71,95,74,0.151)] text-[15px] text-[#259B35] rounded-xl ml-10"
            :class="[{ '!bg-[#259b35] text-white': savedJobs }]" @click.prevent="showSavedJobs">
            Saved Jobs
          </button>
        </div>
      </div>
    </div>
    <div class="max-h-[90%] pt-0 pr-6 pb-6 pl-6 border border-gray-300 rounded-xl overflow-y-scroll">
      <table class="w-full">
        <thead>
          <tr>
            <th v-for="(col, index) in colNames" :key="index"
              class="w-64 2xl:w-96 text-left first:pl-0 px-2.5 whitespace-nowrap font-medium text-brand-gray-100 text-[15px] pt-7 pb-4 sticky top-0 bg-white z-10">
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody v-if="jobs.length > 0 || loading">
          <tr v-if="loading">
            <td colspan="3" align="center" class="w-full">
              <Loading class="mt-10" />
            </td>
          </tr>
          <tr v-else v-for="(d, i) in savedJobs ? bookmarkedJobs : jobs" :key="d" @click="$emit('update', d)">
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              <span class="inline-block cursor-pointer"
                @click.stop="isJobBookmarked(d) ? removeFromBookmark(d) : addToBookmark(d)">
                <img :src="isJobBookmarked(d) ? bookmarkedIcon : bookmarkIcon" class="mr-2" />
              </span>
              {{ truncate(d.companyName, 30) }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ truncate(d.jobTitle, 30) }}
            </td>
            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              {{ truncate(d.location, 30) }}
            </td>

            <td class="text-black font-normal text-[15px] px-2.5 first:pl-0 whitespace-nowrap text-left py-1.5">
              <a :href="d.siteLink" class="curved-button">Details</a>
            </td>
          </tr>
        </tbody>
        <div class="w-full p-5 flex items-center justify-center font-semibold" v-else>No Job Found !</div>
      </table>
    </div>
    <div class="w-full h-[5%] flex py-5 justify-end">
      <div v-if="!savedJobs" class="w-1/2">
        <pagination items-per-page="20" :total-record="paginationData.totalRecord"
          v-model:current-page="paginationData.currentPage" />
      </div>
    </div>

    <!--    <div class="w-full p-5"></div>-->
  </div>
</template>

<script>
import bookmarkIcon from "../../assets/images/bookmark.png";
import bookmarkedIcon from "../../assets/images/bookmarked.png";
import { getAssociatedData, updateAssociatedData, saveAssociatedData } from "../../AssociatedData.js";
import Loading from '../../icons/loading.vue';
import Pagination from '../UI-Kit/Pagination.vue';
import { toast } from 'vue3-toastify';
import { useStudentDemo } from "../../store/StudentDemoStates";
import jobData from "../../assets/js/jobData";

export default {
  components: { Pagination, Loading },
  props: {
    searchKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colNames: ["Company Name", "Job Title", "Location", ""],
      jobs: [],
      bookmarkIcon,
      bookmarkedIcon,
      savedJobs: false,
      loading: false,
      bookmarkedJobs: [],
      paginationData: {
        totalRecord: 0,
        currentPage: 1,
      },
      store: null,
      jobData
    };
  },
  mounted() {
    const store = useStudentDemo();
    this.store = store;
    this.initilize();
  },
  watch: {
    'paginationData.currentPage'() {
      this.getJobs();
    },
  },
  methods: {
    truncate(text, length) {
      if (text?.length > length) {
        return text.substring(0, length) + "...";
      }
      return text;
    },
    async initilize() {
      await this.fetchAssociatedData();
      await this.getJobs()
    },

    isJobBookmarked(job) {
      return this.bookmarkedJobs.some((el) => el.companyName === job.companyName && el.jobTitle === job.jobTitle && el.location === job.location && el.siteLink === job.siteLink);
    },

    addToLocalBookmark(job) {
      this.bookmarkedJobs.push(job)
    },

    removeFromLocalBookmark(job) {
      this.bookmarkedJobs = this.bookmarkedJobs.filter((el) => el.companyName !== job.companyName || el.jobTitle !== job.jobTitle || el.location !== job.location || el.siteLink !== job.siteLink);
    },

    async addToBookmark(job) {
      try {
        this.addToLocalBookmark(job);

        if (!this.store.isStudentDemo) {
          const associatedData = await getAssociatedData();
          if (associatedData && (!associatedData.exploreJobs || !associatedData.exploreJobs.length)) {
            associatedData.exploreJobs = [];
          }
          const alreadyExists = associatedData.exploreJobs.some((el) => el.companyName === job.company?.name && el.jobTittle === job.name && el.location === job.locations[0]?.name && el.siteLink === job.refs?.landing_page);
          if (alreadyExists) {
            toast.error("Already added to bookmark");
            return;
          }
          associatedData.exploreJobs.push({
            companyName: job.companyName,
            jobTitle: job.jobTitle,
            location: job.location,
            siteLink: job.siteLink
          });
          const resp = await updateAssociatedData(associatedData);
          if (resp?.status === 200) {
            toast.success("Added to bookmark");
          }
        }
      } catch (e) {
        this.removeFromLocalBookmark(job);
        toast.error("Error adding to bookmark");
      }
    },

    async removeFromBookmark(job) {
      try {
        this.removeFromLocalBookmark(job);

        if (!this.store.isStudentDemo) {
          const associatedData = await getAssociatedData();
          if (associatedData && (!associatedData.exploreJobs || !associatedData.exploreJobs.length)) {
            associatedData.exploreJobs = [];
          }
          const alreadyExists = associatedData.exploreJobs.some((el) => el.companyName === job.companyName && el.jobTitle === job.jobTitle && el.location === job.location && el.siteLink === job.siteLink);
          if (!alreadyExists) {
            toast.error("Already removed from bookmark");
            return;
          }
          associatedData.exploreJobs = associatedData.exploreJobs.filter((el) => el.companyName !== job.companyName || el.jobTitle !== job.jobTitle || el.location !== job.location || el.siteLink !== job.siteLink);
          const resp = await updateAssociatedData(associatedData);
          if (resp?.status === 200) {
            toast.success("Removed from bookmark");
          }
        }
      } catch (e) {
        this.addToLocalBookmark(job);
        toast.error("Error removing from bookmark");
      }
    },

    async getJobs() {
      this.loading = true;

      if (this.store.isStudentDemo) {
        this.jobs = jobData.map((_) => ({
          companyName: _.company_name || '',
          jobTitle: _.job_title || '',
          location: _.location || '',
          siteLink: _.url || ''
        }))
      } else {
        const res = await this.$request(`/v1/jobs?page=${this.paginationData.currentPage}`, 'GET').catch(() => {
          this.loading = false;
        });
        if (res?.data?.data) {
          if (this.paginationData.currentPage !== 1 && !res.data.data.results.length) {
            this.paginationData.currentPage = 1;
            this.getJobs();
            return;
          }
          if (res.data.data.results?.length) {
            this.jobs = res.data.data.results.map((job) => {
              return {
                companyName: job.company?.name || '',
                jobTitle: job.name || '',
                location: job.locations[0]?.name || '',
                siteLink: job.refs?.landing_page || ''
              }
            })
          }
          this.paginationData.totalRecord = res.data.data.total > 1980 ? 1980 : res.data.data.total;
          this.paginationData.currentPage = res.data.data.page;
        }
      }
      this.loading = false;
    },
    async fetchAssociatedData() {
      if (this.store.isStudentDemo) {
        this.bookmarkedJobs = []
      } else {
        try {
          const associatedData = await getAssociatedData();
          if (associatedData && associatedData.exploreJobs?.length) {
            this.bookmarkedJobs = associatedData.exploreJobs;
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    showSavedJobs() {
      this.savedJobs = !this.savedJobs;
    },
  },
};
</script>
<style scoped>
@import url("./table.css");

.curved-button {
  background-color: #259b35;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.curved-button:hover {
  background-color: #1f7a2d;
}
</style>
