<template>
  <div class="h-full min-h-[500px]">
    <div
        v-if="recentSurvey"
      >
        <div class="">
          <p style="font-family: Inter" class="mt-4 font-[400] text-[#A5AC9D]">
            The information below has been collected from your Workforce Survey.
          </p>
        </div>
        <div class="w-full border border-gray-400 rounded-xl overflow-hidden">
          <div class="w-full grid grid-cols-2 border-b bg-gray-100">
            <div class="w-full p-3 border-r border-gray-400">Questions</div>
            <div class="w-full p-3 ">Answers</div>
          </div>
          <div class="w-full grid grid-cols-2 border-b" v-for="el in recentSurvey" :key="el">
            <div class="w-full p-3 border-r border-gray-400">{{ el.question }}</div>
            <div class="w-full p-3 font-bold">{{ el.answers[0] }}</div>
          </div>
        </div>
      </div>
    <div class="frame-2342">
      <div class="flex items-center justify-center h-full flex-col w-full">
        <div class="max-w-4xl flex items-center justify-center flex-col gap-4">
          <h1 class="inter text-[#259B35] text-[20px]">Wait! Take Your Workforce Survey</h1>
          <p class="inter text-center text-[#000] font-[400px]">
            We put the results from your Workforce Survey here once it is complete. This will help you stay
            organized around which service fits you. Take the survey to get started!
          </p>
          <button
            class="inter px-4 font-[400px] text-[15px] py-2 bg-[#259B35] rounded-full text-white"
            @click="setScreen"
          >
            Take Workforce Survey
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import armed_0 from "../../../../img/armed_0.png";
  import armed_1 from "../../../../img/armed_1.png";
  import { getAssociatedData } from "../../../AssociatedData.js";

  export default {
    name: "HomeFeedStudentDefault",
    components: {},
    props: ["jj", "leftNavigationBarProps", "topNavigationBarProps", "broadcastFeedProps", "careerProfileFrameProps"],

    data() {
      return {
        armed_0,
        armed_1,
        surveys: [],
        ArmedServices:'',
        recentSurvey:'',
        tabs: [
          { name: "All Forms", active: true },
          { name: "Skill Forms", active: false },
          { name: "Personality Forms", active: false },
        ],
        screenZero: true,
        ArmedForcesLink: "",
      };
    },
    async mounted() {
      await this.fetchSurveys();
      const assocData = await getAssociatedData();
      this.recentSurvey = assocData.typeFormResultMap["F0bXyIXq"]

    },
    methods: {
      setScreen(event) {
        event.preventDefault();
        // this.screenZero = false;
        window.location.assign(this.ArmedForcesLink);
      },
      activateTab(index) {
        this.tabs.forEach((tab, tabIndex) => {
          tab.active = index === tabIndex;
        });
      },
      async fetchSurveys() {
        const response = await this.$request("/v1/surveys", "GET");

        this.surveys = await response.data.data.items;
        this.ArmedServices = this.surveys.find((survey) => survey.title.toLowerCase().includes("Workforce Interest".toLowerCase()));
        if(this.ArmedServices){
          this.ArmedForcesLink = this.ArmedServices?._links?.display;
        }
      },
    },
  };
</script>

<style scoped>
  .home-feed-student-default {
    align-items: flex-start;
    background-color: #f3f7f8;
    border: 1px none;
    display: flex;
    gap: 25px;
    min-height: 100vh;
    overflow: hidden;
    padding: 0 18px;
    position: relative;
    max-width: 1512px;
    width: 100%;
  }

  @media all and (max-width: 900px) {
    .home-feed-student-default {
      align-items: flex-start;
      background-color: #f3f7f8;
      border: 1px none;
      display: flex;
      gap: 25px;
      height: 982px;
      overflow: hidden;
      padding: 0 18px;
      position: relative;
      max-width: 100vw !important;
    }
  }

  .flex-col-main {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    min-height: 1579px;
    position: relative;
    width: 100%;
  }

  .frame-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
    min-height: 927px;
    position: relative;
    right: 0%;
    max-width: 291px;
  }

  @media all and (max-width: 1400px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 30px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }

  @media all and (max-width: 600px) {
    .frame-container {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-top: 120px;
      min-height: 927px;
      position: absolute;
      right: 0%;
      max-width: 291px;
    }
  }
  .frame-2354 {
    align-items: center;
    display: flex;
    margin-right: 12px;
    min-width: 109px;
  }

  .bell-1 {
    height: 20px;
    width: 20px;
  }

  .cog-1 {
    height: 20px;
    margin-left: 10px;
    width: 20px;
  }

  .overlap-group {
    align-items: flex-start;

    border: 1px solid;
    border-color: var(--celeste);
    border-radius: 23px;
    display: flex;
    height: 46px;
    margin-left: 13px;
    min-width: 46px;
    padding: 10px 11px;
  }

  .jj {
    color: var(--x475f4a);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    min-height: 24px;
    white-space: nowrap;
  }

  .broadcast-feed {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    min-height: 900px;
    width: 100%;
  }

  .overlap-HomeFeedStudentDefaultgroup1 {
    border-radius: 30px;
    /* min-height: 951px; */
    width: 100%;
  }

  @media all and (max-width: 600px) {
    .overlap-HomeFeedStudentDefaultgroup1 {
      border-radius: 30px;
      /* min-height: 951px; */
      width: 865px;
      margin-top: 80px;
    }
  }

  .frame-2342 {
    align-items: flex-start;
    background-color: transparent;
    border-radius: 30px;
    display: inline-flex;
    gap: 10px;
    width: 100%;
    background-color: #fff;
    /* left: 0; */
    overflow: hidden;
    height: 100%;
    overflow-y: scroll;
    padding: 40px 28px;
    /* position: absolute;s */
    /* top: 0; */
    z-index: -99;
  }

  .posts-feeed::-webkit-scrollbar {
    display: none;
  }

  .grid_div {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 40px;
    width: 100%;
  }

  .first_div div h1 {
    color: #000;
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .personal_info {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  .personal_info div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .personal_info div p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .personal_info .title_t {
    color: #a5ac9d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;

    display: inline-flex;
    font-weight: 500;
    line-height: normal;
  }
  .personal_info_ .title_t {
    color: #a5ac9d;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;

    font-weight: 500;
    line-height: normal;
  }

  .flex_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .second p {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .academics {
    margin-top: 30px;
  }
  .academics h1 {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exams {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
  }
  .exams_one {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
  }

  .exams_one h1 {
    color: #475f4a;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .exams_one p {
    color: #636060;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcd {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .abcd p {
    color: #636060;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcd_para {
    color: #000;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .abcde {
    display: flex;
    align-items: flex-start;
    margin-top: 30px;
    flex-direction: column;
    gap: 10px;
  }

  .second {
    margin-top: 30px;
  }

  .btns {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 14px;
  }
  .single_btn {
    color: #475f4a;
    font-family: Inter;
    font-size: 15px;

    border-radius: 100px;
    display: inline-flex;
    padding: 5px 20px;
    align-items: flex-start;
    gap: 10px;
    background: #f0f4f0;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media all and (max-width: 1000px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  @media all and (max-width: 600px) {
    .grid_div {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 40px;
      width: 100%;
    }
  }

  tbody:before {
    content: "@";
    display: block;
    line-height: 10px;
    text-indent: -99999px;
  }
  tr.spaceUnder > td {
    padding-bottom: 15px;
  }

  .inter {
    font-family: Inter;
  }
</style>
